import { Injectable } from '@angular/core';

@Injectable()
export class DataService {
    private subj_id: any;
    private chapt_id: any;
    private set_screen: boolean;
    private data: any = [];
    private message: any = [];
    constructor() { }

    public get_subj_id(): any {
        return this.subj_id;
    }

    public set_subj_id(subj_id: any): void {
        this.subj_id = subj_id;
    }

    public get_chapt_id(): any {
        return this.chapt_id;
    }

    public set_chapt_id(chapt_id: any): void {
        this.chapt_id = chapt_id;
    }

    public get_set_screen(): boolean {
        return this.set_screen;
    }

    public set_set_screen(set_screen: boolean): void {
        this.set_screen = set_screen;
    }

    public set_top_questions(viewMode: boolean, selectIndex: number): void {
        this.data = {
            viewMode: viewMode,
            selectIndex: selectIndex,
        };
    }

    public get_top_questions(): boolean {
        return this.data;
    }

    public set_msg(msg: any, type: any): void {
        this.message = {
            msg: msg,
            type: type,
        };
    }

    public get_msg(): void {
        return this.message;
    }
}
