import {
    Injectable,
    NgModule,
    ApplicationRef,
    ComponentFactoryResolver,
    Injector,
} from '@angular/core';

import { ModalWindowComponent } from './modal-window.component';

@NgModule({
    declarations: [
        ModalWindowComponent,
    ],
})

@Injectable()
export class ModalService {
    constructor(
        private _appRef: ApplicationRef,
        private _cfResolver: ComponentFactoryResolver,
        private _injector: Injector,
    ) { }

    open(title, content) {
        const contentView = content.createEmbeddedView();
        const modalComponentFactory = this._cfResolver.resolveComponentFactory(ModalWindowComponent);
        const modalComponent = modalComponentFactory.create(this._injector, [contentView.rootNodes]);

        if (title) modalComponent.instance.title = title;

        document.body.appendChild(modalComponent.location.nativeElement);

        contentView.detectChanges();
        this._appRef.attachView(modalComponent.hostView);
    }
}
