import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../../environments/environment';
import { Globals } from '../../../global';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit {
    @Input() position = 'normal';
    user: any;
    show: boolean = false;
    show_sideMenu: boolean = false;
    picture: 'assets/icons/profile.png';
    show_examMenu: boolean = false;
    brand_website: string = environment.brand_website;
    prof_id: any;
    userMenu = [
        {
            title: 'My Profile',
            icon: 'person-outline',
            link: '/admin/profile',
            onlyPicture: false,
        },
        {
            title: 'My Subscription',
            icon: 'star-outline',
            link: '/admin/profile',
            onlyPicture: false,
        },
        // {
        //     title: 'Slingshot Credits',
        //     icon: 'nb-heart',
        //     link: '#',
        //     onlyPicture: false,
        // },
        {
            title: 'Settings',
            icon: 'settings-2-outline',
            link: '/admin/profile',
            onlyPicture: false,
        },
        {
            title: 'Log out',
            icon: 'power-outline',
            link: '/logout',
            onlyPicture: false,
        },
    ];
    helpMenu = [
        {
            title: 'Exam Guilines',
            icon: 'star-outline',
            link: '#',
        },
        {
            title: 'FAQs',
            icon: 'question-mark-circle-outline',
            link: '#',
        },
        {
            title: 'Support',
            icon: 'checkmark-outline',
            link: '#',
        },
    ];
    isAvailable: any = true;
    sideMenu = [
        {
            title: 'Choose Your Exam',
            url: environment.brand_website + '/entrance-exam-jee/',
        },
        {
            title: 'Why Slingshot',
            url: environment.brand_website + '/why-choose-us/',
        },
        {
            title: 'Pricing Plans',
            url: environment.brand_website + '/pricing-plans/',
        },
        {
            title: 'Partner With Us',
            url: environment.brand_website + '/partner-with-us/',
        },
    ];
    subMenu = [
        {
            title: 'JEE MAIN',
            url: environment.brand_website + '/exams/jee-main/',
        },
        {
            title: 'MH - CET',
            url: environment.brand_website + '/exams/mht-cet/',
        },
        {
            title: 'NEET',
            url: environment.brand_website + '/exams/neet/',
        },
    ];
    reverse_menu = this.sideMenu.slice().reverse();
    constructor(
        private sidebarService: NbSidebarService,
        private menuService: NbMenuService,
        private router: Router,
        public globals: Globals,
        private authService: NbAuthService,
    ) {
        this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
            if (token.isValid()) {
                this.show = true;
                this.user = token.getPayload();
                if (this.user !== undefined && this.user && !!this.user.StudentProfile) {
                    this.prof_id = this.user.StudentProfile.professor_id;
                }
            }
        });
        this.isAvailable = router.url.includes('admin');
        router.events.subscribe(() => {
            this.isAvailable = router.url.includes('admin');
        });
    }

    ngOnInit() {
        this.menuService.onItemClick()
            .pipe(
                filter(({ tag }) => tag === 'context-menu'),
                map(({ item: { title } }) => title),
            )
            .subscribe(title => {
                if (title === 'My Profile' || title === 'My Subscription' || title === 'Settings') {
                    this.globals.changedMenu(title);
                    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                        this.router.navigate(['/admin/profile']));
                } else {
                    this.globals.changedMenu('');
                }
            });
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, 'menu-sidebar');
        return false;
    }

    goToHome() {
        this.menuService.navigateHome();
    }

    unlockBtn() {
        this.globals.changePlanButton(true);
        this.router.navigate(['/admin/plan'], { queryParams:  { r: this.router.url }, skipLocationChange: false});
    }

    clickSideMenu() {
        this.show_sideMenu = !this.show_sideMenu;
    }

    clickExamMenu() {
        this.show_examMenu = !this.show_examMenu;
    }

}
