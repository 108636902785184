import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxRegisterComponent } from './register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ThemeModule } from '../../@theme/theme.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';
import { NbDatepickerModule } from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [NgxRegisterComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ThemeModule,
        RecaptchaV3Module,
        NbDatepickerModule,
        NgbModule,
        NgSelectModule,
    ],
    providers: [
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: `${environment.captchaSiteKey}`,
        },
    ],
})
export class RegisterModule { }
