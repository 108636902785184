import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpEvent, HttpHandler, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CryptoService } from '../crypto.service';
import { AuthService } from '../auth/auth.service';
import { Globals } from '../global';
const urls = ['register', 'login', 'changePassword', 'resetPassword'];

@Injectable({
    providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: NbAuthService,
        private router: Router,
        private cryptopService: CryptoService,
        private authServiceCustom: AuthService,
        public globals: Globals,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        const started = Date.now();
        const url = request.urlWithParams;
        const method = request.method;

        request = this.encryptDecrypt(request, 'set');

        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.isValid()) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: 'Bearer ' + token,
                        },
                    });
                }
            });

        if (!request.headers.has('Content-Type')) {
            request = request.clone({
                headers: request.headers.set('Content-Type', 'application/json'),
            });
        }

        request = request.clone({
            headers: request.headers.set('Accept', 'application/json'),
        });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && !environment.production) {
                    console.groupCollapsed(
                        `[success] ${method} "${url}" succeeded in ${Date.now() - started} ms.`,
                    );
                    console.info(event);
                    console.groupEnd();
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    // auto logout if 401 response returned from api
                    this.authServiceCustom.isLoggedIn = false;
                    this.globals.loggedIn(false);
                    this.router.navigate(['/']);
                }
                if (!environment.production) {
                    console.groupCollapsed(
                        `[error] ${method} "${url}" failed in ${Date.now() - started} ms.`,
                    );
                    console.error('[error]', {
                        error: error,
                        reason: error && error.error && error.error.error ? error.error.error : '',
                        status: error.status,
                    });
                    console.groupEnd();
                }
                request = this.encryptDecrypt(request, 'get');
                return throwError(error);
            }));
    }

    encryptDecrypt(request, task) {
        if (urls.some(v => request.url.includes(v))) {
            if (request.body.hasOwnProperty('current_pswd') && request.body.hasOwnProperty('new_pswd')) {
                request.body.current_pswd = this.cryptopService.getSetGo(request.body.current_pswd, task);
                request.body.new_pswd = this.cryptopService.getSetGo(request.body.new_pswd, task);
            } else if (request.body.hasOwnProperty('password')) {
                request.body.password = this.cryptopService.getSetGo(request.body.password, task);
            }
        }
        return request;
    }

}
