import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../environments/environment';

const keyS = CryptoJS.enc.Utf8.parse(environment.ssleicng);
const ivS = CryptoJS.enc.Utf8.parse(environment.ssleicng);
const optionsS = {
    keySize: 128 / 8,
    iv: ivS,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};

const keyP = CryptoJS.enc.Utf16.parse(environment.psklrixnz);
const ivP = CryptoJS.enc.Utf16.parse(environment.psklrixnz);
const optionsP = {
    keySize: 128 / 4,
    iv: ivP,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.Iso97971,
};

@Injectable({
    providedIn: 'root',
})
export class CryptoService {
    constructor() { }

    getSetGo(value, task) {
        return (task === 'set') ? this.set(value) : this.get(value);
    }

    // The set method is use for encrypt the value.
    set(value) {
        if (value) {
            const val = CryptoJS.enc.Utf8.parse(value.toString());
            const encrypted = CryptoJS.AES.encrypt(val, keyS, optionsS);
            return encrypted.toString();
        }
        return value;
    }

    // The get method is use for decrypt the value.
    get(value) {
        if (value) {
            const decrypted = CryptoJS.AES.decrypt(value, keyS, optionsS);
            return decrypted.toString(CryptoJS.enc.Utf8);
        }
        return value;
    }

    setParam(value) {
        if (value) {
            const val = CryptoJS.enc.Utf16.parse(value.toString());
            const encrypted = CryptoJS.DES.encrypt(val, keyP, optionsP);
            return encrypted.toString().replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');
        }
        return value;
    }
}
