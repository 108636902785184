<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" status="danger" role="alert" closable (close)="errors = []">
  Oh snap! {{ errors[0] }}
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" status="success"  role="alert" closable (close)="messages = []">
    Hooray! {{ messages[0] }}
</nb-alert>

<div class="row requestPass-section">
  <div class="login-form-wrapper col-xl-4 col-lg-8 col-md-8 col-sm-8 ml-auto mr-auto">
    <div class="login-heading text-center">
      <h1 id="title" class="login-title">Forgot Password</h1>
      <p class="login-subtitle">Enter your email address and we’ll send a link to reset your password</p>
    </div>

    <form (ngSubmit)="requestPass()" #requestPassForm="ngForm" aria-labelledby="title">

      <div class="form-control-group">
        <div class="input-container input-text">
          <img src="assets/images/icons/email.png" class="img-fluid input-icon">
          <div class="floating-label">
            <input class="floating-input" nbInput [(ngModel)]="user.email" #email="ngModel" id="input-email"
              name="email" pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}" placeholder=" " autofocus fullWidth autocapitalize="none"
              [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
              [required]="getConfigValue('forms.validation.email.required')"
              [attr.aria-invalid]="email.invalid && email.touched ? true : null">
            <label class="label custom-label" for="input-email">Enter your email address:</label>
          </div>
          <ng-container *ngIf="email.invalid && email.touched">
            <p class="error-message" *ngIf="email.errors?.required">
              Email is required!
            </p>
            <p class="error-message" *ngIf="email.errors?.pattern">
              Email should be the real one!
            </p>
          </ng-container>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button class="submit btn btn-lg login-button" fullWidth status="success"
            [disabled]="submitted || !requestPassForm.valid" [class.btn-pulse]="submitted">
            <span class="login-text">Reset password</span>
          </button>
        </div>
      </div>
    </form>

    <section class="another-action text-center mt-4" aria-label="Sign in or sign up">
      <p class="sign-in-text">
        <a class="text-link left" routerLink="../login">Back to Log In</a>
        <a routerLink="../register" class="text-link right">Register</a>
      </p>
    </section>
  </div>
</div>
