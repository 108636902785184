import { Component, OnDestroy } from '@angular/core';
import { delay, withLatestFrom, takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Globals } from '../../../global';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import {
    NbMediaBreakpoint,
    NbMediaBreakpointsService,
    NbMenuItem,
    NbMenuService,
    NbSidebarService,
    NbThemeService,
} from '@nebular/theme';
import { Observable, fromEvent, Subscription } from 'rxjs';
import { StateService } from '../../../@core/utils';

@Component({
    selector: 'ngx-structure-layout',
    styleUrls: ['./structure.layout.scss'],
    template: `
    <nb-layout [center]="layout.id === 'center-column'" windowMode style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;" unselectable="on" >
        <nb-layout-header fixed [ngClass]="{'loggedout-nav': auth_status, 'header-wrap': !auth_status}" *ngIf="globals.header_status">
            <ngx-header [position]="sidebar.id === 'start' ? 'normal': 'inverse'"></ngx-header>
        </nb-layout-header>

        <nb-layout-header class="alert-wrapper" *ngIf="globals.header_status && !auth_status && !isAppOnline && alertIsOpen">
            <div class="dashboard-alert-msg">
                <nb-alert status="info" closable (close)="onClose()">
                    <div><img src="assets/images/icons/app-offline.png" width="40" class="img-fluid"><div class="current-status-info"><p>You are currently offline.</p><p>Some features may not function.</p></div></div>
                </nb-alert>
            </div>
        </nb-layout-header>

        <nb-sidebar class="menu-sidebar" [tag]="sidebarTag" [responsive]="true" [compactedBreakpoints]="['xs', 'is', 'sm', 'md', 'lg']" [collapsedBreakpoints]="['xs', 'is', 'sm', 'md', 'lg']" *ngIf="globals.sidebar_status">
            <nb-menu [items]="items" tag="leftMenu"></nb-menu>
            <a (click)="unlockBtn()" *ngIf="!prof_id">
                <div class="unlock-btn-mobile">UPGRADE PLAN</div>
            </a>
        </nb-sidebar>

        <nb-layout-column class="main-content p-0 m-0">
            <ng-content select="router-outlet"></ng-content>
        </nb-layout-column>

        <nb-layout-column start class="small" *ngIf="layout.id === 'two-column' || layout.id === 'three-column'">
            <nb-menu [items]="subMenu"></nb-menu>
        </nb-layout-column>

        <nb-layout-column class="small" *ngIf="layout.id === 'three-column'">
            <nb-menu [items]="subMenu"></nb-menu>
        </nb-layout-column>
    </nb-layout>
  `,
})
export class StructureLayoutComponent implements OnDestroy {
    subMenu: NbMenuItem[] = [
        {
            title: 'PAGE LEVEL MENU',
        },
    ];
    items = [
        {
            title: 'Dashboard',
            icon: 'menu-outline',
            expanded: true,
            children: [
                {
                    title: 'Activity Overview',
                    link: '/admin/dashboard', // goes into angular `routerLink`
                },
                {
                    title: 'Performance Tracker',
                    link: '/admin/performance-tracker',
                },
            ],
        },
        {
            title: 'Test Centre',
            icon: 'edit-outline',
            link: '/admin/test-center',
            expanded: true,
            children: [
                {
                    title: 'Test Centre Home',
                    link: '/admin/test-center', // goes into angular `routerLink`
                    class: 'demo',
                },
                {
                    title: 'Tests by Chapters',
                    link: '/admin/practice-test/chapter',
                },
                {
                    title: 'Tests by Subjects',
                    link: '/admin/practice-test/subject',
                },
                {
                    title: 'Mock Tests',
                    link: '/admin/prelim-test',
                },
            ],
        },
        {
            title: 'Past Papers',
            icon: 'grid-outline',
            link: '/admin/previous-year',
        },
        {
            title: 'Revision List',
            icon: 'list-outline',
            link: '/admin/revision-list',
        },
    ];

    layout: any = {};
    sidebar: any = {};
    isSidebarAvailable: any;
    isLogginAvailable: any;
    private alive = true;
    isAppOnline: boolean = window.navigator.onLine;
    currentTheme: string;
    sidebar_status: boolean;
    auth_status: boolean;
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;
    subscriptions = new Subscription();
    alertIsOpen: boolean = false;
    prof_id: any;
    user: any;

    constructor(
        protected stateService: StateService,
        protected menuService: NbMenuService,
        protected themeService: NbThemeService,
        protected bpService: NbMediaBreakpointsService,
        protected sidebarService: NbSidebarService,
        public globals: Globals,
        protected router: Router,
        private authService: NbAuthService,
    ) {
        router.events.subscribe(() => {
            this.auth_status = !router.url.includes('admin');
        });
        this.stateService.onLayoutState()
            .pipe(takeWhile(() => this.alive))
            .subscribe((layout: string) => this.layout = layout);

        this.stateService.onSidebarState()
            .pipe(takeWhile(() => this.alive))
            .subscribe((sidebar: string) => {
                this.sidebar = sidebar;
            });

        const isBp = this.bpService.getByName('is');
        this.menuService.onItemSelect()
            .pipe(takeWhile(() => this.alive),
                withLatestFrom(this.themeService.onMediaQueryChange()),
                delay(20),
            )
            .subscribe(([_item, [_any, bpTo]]: [any, [NbMediaBreakpoint, NbMediaBreakpoint]]) => {
                if (bpTo.width <= isBp.width) {
                    this.sidebarService.collapse('menu-sidebar');
                }
            });

        this.themeService.getJsTheme()
            .pipe(takeWhile(() => this.alive))
            .subscribe(theme => {
                this.currentTheme = theme.name;
            });

        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');
        this.subscriptions.add(
            this.onlineEvent.subscribe(() => this.isAppOnline = true),
        );
        this.subscriptions.add(
            this.offlineEvent.subscribe(() => {
                this.isAppOnline = false;
                this.alertIsOpen = true;
            }),
        );

        this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
            if (token.isValid()) {
                this.user = token.getPayload();
                if (this.user !== undefined && this.user && !!this.user.StudentProfile) {
                    this.prof_id = this.user.StudentProfile.professor_id;
                    const exist_index = this.items.findIndex(o => o.title === 'Institute Tests');

                    // remove ci menu if exist
                    if (exist_index > -1) {
                        this.items.splice(exist_index, 1);
                    }

                    // add ci menu based on condition
                    if (this.prof_id) {
                        const new_index = this.items.findIndex(o => o.title === 'Dashboard');
                        this.items.splice(new_index + 1, 0, {
                            title: 'Institute Tests',
                            link: '/admin/ci-tests',
                            icon: 'grid-outline',
                            expanded: true,
                            children: [
                                {
                                    title: 'Tests by Chapters',
                                    link: '/admin/ci-tests/chapter',
                                },
                                {
                                    title: 'Tests by Subjects',
                                    link: '/admin/ci-tests/subject',
                                },
                                {
                                    title: 'Mock Tests',
                                    link: '/admin/ci-tests/prelim',
                                },
                            ],
                        });
                    }
                }
            }
        });
    }

    toggle() {
        this.sidebarService.toggle(false, 'menu-sidebar');
    }

    toggleCompact() {
        this.sidebarService.toggle(true, 'right');
    }

    collapseMenu() {
        this.menuService.collapseAll('leftMenu');
    }

    ngOnDestroy() {
        this.alive = false;
        if (this.subscriptions !== undefined) {
            this.subscriptions.unsubscribe();
        }
    }

    unlockBtn() {
        this.globals.changePlanButton(true);
        this.router.navigate(['/admin/plan'], { queryParams: { r: this.router.url }, skipLocationChange: false });
    }

    onClose() {
        this.alertIsOpen = false;
    }
}

// TODO : Keep this safe untill all fixes done
// <nb-sidebar class="menu-sidebar"
// tag="menu-sidebar"
// responsive
// [end]="sidebar.id === 'end'">
// <ng-content select="nb-menu"></ng-content>
// </nb-sidebar>

// collapse
// <p (click)="collapseMenu()" class="collapse-all">Close all</p>

// toggle complete
// <button nbButton status="success" size="tiny" (click)="toggle()">Toggle</button>

// head
// <nb-layout-header fixed *ngIf="router.url !== '/admin/plan'">
//         <ngx-header [position]="sidebar.id === 'start' ? 'normal': 'inverse'"></ngx-header>
//       </nb-layout-header>
