import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbAuthService, NbAuthResult } from '@nebular/auth';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../api.service';
import { Globals } from '../../global';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'ngx-nb-oauth2-callback',
    template: '',
})
export class NbOAuth2CallbackComponent implements OnDestroy, OnInit {
    private destroy$ = new Subject<void>();

    constructor(private authService: NbAuthService,
        private api: ApiService,
        private globals: Globals,
        private router: Router) {
        this.authService.authenticate('google')
            .pipe(takeUntil(this.destroy$))
            .subscribe((authResult: NbAuthResult) => {
                this.getProfileData(authResult);
            });
    }

    getProfileData(authResult) {
        const t = authResult.response.access_token;
        this.api.getProfileDataGoogle(t).subscribe((g: { name: '', given_name: '', family_name: '', email: '', picture: '' }) => {
            const data = {
                name: g.name,
                first_name: g.given_name,
                last_name: g.family_name,
                email: g.email,
                picture: g.picture,
                token: t,
            };

            this.api.googleAuth(data).subscribe(d => {
                if (d.user !== undefined) {
                    localStorage.setItem('google_email', d.user.email);
                    localStorage.setItem('google_name', d.user.first_name + ' ' + d.user.last_name);
                }

                if (d.user !== undefined && d.user.token_set) {
                    if (d.user.signed_in) {
                        this.router.navigate(['/'], { state: { data: { login: true } } });
                    } else {
                        this.router.navigate(['/register'], { state: { data: { login: true } } });
                    }
                } else {
                    this.router.navigate(['/'], { state: { data: { login: true } } });
                }
            });
        });
    }

    ngOnInit() {
        this.globals.sidebarStatus(false);
        this.globals.headerStatus(true);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
