import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import {
    NbAuthModule,
    NbPasswordAuthStrategy,
    NbAuthJWTToken,
    NbOAuth2AuthStrategy,
    NbOAuth2ResponseType,
} from '@nebular/auth';
import {
    NbAlertModule,
    NbButtonModule,
    NbCheckboxModule,
    NbInputModule,
    NbStepperModule,
    NbSelectModule,
} from '@nebular/theme';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ThemeModule } from './@theme/theme.module';
import { NbDialogModule, NbDatepickerModule } from '@nebular/theme';
import { Globals } from './global';
import { ModalService } from './modal.services';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard, LoginGuard } from './auth/auth.guard';
import { NgSelectModule } from '@ng-select/ng-select';
import { environment } from '../environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';
import { httpInterceptorProviders } from './interceptors/index';
import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';
import { AnalyticsService } from './@core/utils/analytics.service';
import { CryptoService } from './crypto.service';
import {
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule,
    NgxUiLoaderConfig,
    SPINNER,
} from 'ngx-ui-loader';
import { CookieService } from 'ngx-cookie-service';
import { RequestCache, RequestCacheWithMap } from './request-cache.service';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { UpdatesNotificationComponent } from './updates-notification/updates-notification.component';
import { RegisterModule } from './auth/register/register.module';
import { NgxRegisterCodeComponent } from './auth/register-code/register-code.component';
import { NgxLoginComponent } from './auth/login/login.component';
import { NgxLogoutComponent } from './auth/logout.component';
import { NbOAuth2LoginComponent } from './auth/nb-oauth2-login/nb-oauth2-login.component';
import { NbOAuth2CallbackComponent } from './auth/nb-oauth2-callback/nb-oauth2-callback.component';
import { RequestPasswordComponent } from './auth/request-password/request-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { DataService } from './admin/data.service';
import { ErrorsComponent } from './admin/test-home/errors/errors.component';
import { TestsComponent } from './admin/test-home/tests/tests.component';
import { ScullyLibModule } from '@scullyio/ng-lib';

const apiUrl = `${environment.apiUrl}/`;

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor() {
        Sentry.init({
            dsn: 'https://9539c41226cd42598b13a7bada3fa809@sentry.io/1786040',
            environment: environment.site_state,
            maxBreadcrumbs: 50,
            integrations: [
                new RewriteFrames(),
                new Sentry.Integrations.Breadcrumbs({ console: false }),
            ],
            beforeSend(event, hint) {
                // Note: issue with double entries during http exceptions:
                // https://github.com/getsentry/sentry-javascript/issues/2169
                // Note: issue with a second entry not being set correctly (as a non-error):
                // https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-554932519
                let hi = false;
                const ev = event.exception.values[0].value.startsWith('Non-Error exception captured');
                if (!!hint && !!hint.originalException && !!hint.originalException['message']) {
                    hi = hint.originalException['message'].startsWith('Non-Error exception captured');
                }
                if (ev || hi) {
                    if (!event.extra.__serialized__) {
                        return null;
                    }
                    let realErrMsg = event.extra.__serialized__.error ? event.extra.__serialized__.error.message : null;
                    realErrMsg = realErrMsg || event.extra.__serialized__.message;
                    // this is a useless error message that masks the actual error.  Lets try to set it properly
                    event.exception.values[0].value = realErrMsg;
                    event.message = realErrMsg;
                }
                return event;
            },
        });
    }

    extractError(error) {
        // Try to unwrap zone.js error.
        // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
        if (error && error.ngOriginalError) {
            error = error.ngOriginalError;
        }

        // We can handle messages and Error objects directly.
        if (typeof error === 'string' || error instanceof Error) {
            return error;
        }

        // If it's http module error, extract as much information from it as we can.
        if (error instanceof HttpErrorResponse) {
            // The `error` property of http exception can be either an `Error` object, which we can use directly...
            if (error.error instanceof Error) {
                return error.error;
            }

            // ... or an`ErrorEvent`, which can provide us with the message but no stack...
            if (error.error instanceof ErrorEvent) {
                return error.error.message;
            }

            // ...or the request body itself, which we can use as a message instead.
            if (typeof error.error === 'string') {
                return `Server returned code ${error.status} with body "${error.error}"`;
            }

            // If we don't have any detailed information, fallback to the request message itself.
            return error.message;
        }

        // The above code doesn't always work since 'instanceof' relies on the object being created with the 'new' keyword
        if (!!error) {
            if (error.error && error.error.message) {
                return error.error.message;
            }

            if (error.message) {
                return error.message;
            }
        }

        // Skip if there's no error, and let user decide what to do with it.
        return null;
    }

    handleError(error: any) {
        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (error && chunkFailedMessage.test(error.error || error.message || error.originalError || error)) {
            window.location.reload();
        }

        const extractedError = this.extractError(error) || 'Handled unknown error';
        // Capture handled exception and send it to Sentry.
        Sentry.captureException(extractedError);

        // When in development mode, log the error to console for immediate feedback.
        if (!environment.production) {
            console.error(extractedError);
        }
    }
}

export function getErrorHandler(): ErrorHandler {
    if (environment.production) {
        return new SentryErrorHandler();
    } else {
        return new ErrorHandler();
    }
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    bgsColor: '#5a53ff',
    bgsPosition: 'bottom-left',
    bgsType: SPINNER.ballScaleMultiple, // background spinner type
    masterLoaderId: 'loader-01',
    blur: 7,
    logoUrl: 'assets/images/icons/use-on-white.png',
    logoSize: 68,
    fgsColor: '#5a53ff',
    fgsType: SPINNER.ballScaleMultiple, // foreground spinner type
    pbColor: '#5a53ff',
    pbThickness: 3, // progress bar thickness,
    overlayColor: 'rgba(210, 210, 210, 0.8)',
};

const formSetting: any = {
    redirectDelay: 0,
};

@NgModule({
    declarations: [
        AppComponent,
        NgxLoginComponent,
        NgxLogoutComponent,
        NgxRegisterCodeComponent,
        NbOAuth2CallbackComponent,
        NbOAuth2LoginComponent,
        RequestPasswordComponent,
        ResetPasswordComponent,
        UpdatesNotificationComponent,
        ErrorsComponent,
        TestsComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NgSelectModule,
        NbDialogModule.forRoot(),
        NgbModule,
        CoreModule.forRoot(),
        ThemeModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbAuthModule.forRoot({
            strategies: [
                NbPasswordAuthStrategy.setup({
                    name: 'email',
                    baseEndpoint: apiUrl,
                    login: {
                        endpoint: 'users/login',
                        method: 'post',
                        requireValidToken: false,
                        redirect: {
                            success: '/admin/dashboard',
                            failure: null,
                        },
                        defaultErrors: ['Email Address/Password combination is not correct, please try again..'],
                        defaultMessages: ['You have been successfully logged in.'],
                    },
                    register: {
                        endpoint: 'users',
                        method: 'post',
                        redirect: {
                            success: '/admin/plan',
                            failure: '/register',
                        },
                        defaultErrors: ['Email already exists, Please try again.'],
                        defaultMessages: ['You have been successfully registered.'],
                    },
                    requestPass: {
                        endpoint: 'users/requestPassword',
                        method: 'post',
                        redirect: {
                            success: '/login',
                            failure: null,
                        },
                        defaultErrors: ['Email does not exist, Please try again.'],
                        defaultMessages: ['You will receive an email with a link to reset your password.'],
                    },
                    resetPass: {
                        endpoint: 'users/resetPassword',
                        method: 'put',
                        redirect: {
                            success: '/login',
                            failure: '/login',
                        },
                        resetPasswordTokenKey: 'token',
                        defaultErrors: ['Something went wrong, please try again.'],
                        defaultMessages: ['Your password has been successfully changed.'],
                    },
                    logout: {
                        endpoint: 'users/logout',
                        method: 'post',
                    },
                    token: {
                        class: NbAuthJWTToken,
                        key: 'token', // this parameter tells where to look for the token
                    },
                    errors: {
                        getter: errorGetter,
                    },
                }),
                NbOAuth2AuthStrategy.setup({
                    name: 'google',
                    clientId: `${environment.google.clientId}`,
                    clientSecret: `${environment.google.clientSecret}`,
                    authorize: {
                        endpoint: `${environment.google.endpoint}`,
                        responseType: NbOAuth2ResponseType.TOKEN,
                        scope: `${environment.google.scope}`,
                        redirectUri: `${environment.google.redirectUri}`,
                    },
                }),
            ],
            forms: {
                login: formSetting,
                register: formSetting,
                requestPassword: formSetting,
                resetPassword: formSetting,
                logout: formSetting,
                validation: {
                    password: {
                        required: true,
                        minLength: 8,
                        maxLength: 50,
                    },
                },
            },
        }),
        NbEvaIconsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
        NgxUiLoaderHttpModule.forRoot({
            exclude: [apiUrl + '/paymentStatus', '/'],
        }),
        FormsModule,
        ReactiveFormsModule,
        NbAlertModule,
        NbInputModule,
        NbButtonModule,
        NbCheckboxModule,
        NbStepperModule,
        NbSelectModule,
        RegisterModule,
        ScullyLibModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        AuthGuard,
        LoginGuard,
        Globals,
        ModalService,
        NgbActiveModal,
        AnalyticsService,
        CookieService,
        CryptoService,
        { provide: RequestCache, useClass: RequestCacheWithMap },
        httpInterceptorProviders,
        { provide: ErrorHandler, useFactory: getErrorHandler },
        DataService,
    ],
})

export class AppModule { }

export function errorGetter(module, res, options) {
    return !res.error.success ? res.error.error : options[module].defaultErrors;
}
