import { Component, OnDestroy } from '@angular/core';
import { NbAuthService, NbAuthOAuth2Token } from '@nebular/auth';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'ngx-nb-oauth2-login',
    template: `
    <div class='g-sign-in-button' *ngIf="!token" (click)="login()">
        <div class=content-wrapper>
            <div class='logo-wrapper'>
                <img src='https://developers.google.com/identity/images/g-logo.png'>
            </div>
            <span class='text-container'>
                <span>Sign in with Google</span>
            </span>
        </div>
    </div>`,
    styles: [`.btn{
        font-size:0.8rem;
      }
      *, *:before, *:after {
        box-sizing: border-box;
      }
      .g-sign-in-button {
        margin: 10px;
        display: inline-block;
        width: 240px;
        height: 50px;
        background-color: #4285f4;
        color: #fff;
        border-radius: 1px;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
        transition: background-color .218s, border-color .218s, box-shadow .218s;
      }
      .g-sign-in-button:hover {
        cursor: pointer;
        -webkit-box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
        box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
      }
      .g-sign-in-button:active {
        background-color: #3367D6;
        transition: background-color 0.2s;
      }
      .g-sign-in-button .content-wrapper {
        height: 100%;
        width: 100%;
        border: 1px solid transparent;
      }
      .g-sign-in-button img {
          width: 18px;
          height: 18px;
      }
      .g-sign-in-button .logo-wrapper {
         padding: 15px;
         background:#fff;
         width: 48px;
         height: 100%;
         border-radius: 1px;
         display: inline-block;
         float:left;
         line-height: 0rem;
      }
      .g-sign-in-button .text-container {
          font-family: Roboto,arial,sans-serif;
          font-weight: 500;
          letter-spacing: .21px;
          font-size: 16px;
          line-height: 48px;
          vertical-align: top;
          border: none;
          display: inline-block;
          text-align: center;
          width: 180px;
      }`],
})
export class NbOAuth2LoginComponent implements OnDestroy {
    private destroy$ = new Subject<void>();
    token: NbAuthOAuth2Token;

    constructor(private authService: NbAuthService) { }

    login() {
        this.authService.authenticate('google')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {});
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
