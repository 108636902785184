<nb-alert *ngIf="error_msg" status="danger" role="alert" closable (close)="error_msg=''">
  Oh snap! {{ error_msg }}
</nb-alert>

<nb-alert *ngIf="success_msg" status="success" role="alert" closable (close)="success_msg=''">
  Hooray! {{ success_msg }}
</nb-alert>

<div class="row register-section">
  <div class="register-form-wrapper col-xl-4 col-lg-8 col-md-8 col-sm-8 ml-auto mr-auto">
    <div class="container register-form student-test-form">
      <nb-stepper orientation="horizontal" [selectedIndex]="selectedIndex">

        <!-- First Step -->
        <nb-step [stepControl]="registerUsingCodeForm" label="Register">
          <h1 id="title" class="register-title">Welcome to Slingshot Students!</h1>
          <p class="register-subtitle">Please fill in the following details to Sign up:</p>
          <form #registerUsingCodeForm="ngForm" aria-labelledby="title" method="POST" (ngSubmit)="onRegister()">
            <div class="row">
              <div class="col">
                <div class="form-control-group">
                  <div class="input-container input-text">
                    <div class="floating-label">
                      <label class="label custom-label label-fixed" for="input-regCode">Registration code</label>
                      <i class="fa fa-th-list input-icon"></i>
                      <input nbInput id="input-regCode" name="regCode" autocapitalize="none" type="text" placeholder="e.g. XYLM1A" fullWidth required disabled #regCode="ngModel" [(ngModel)]="user.regCode">
                      <ng-container *ngIf="regCode.invalid && regCode.touched">
                        <p class="error-message" *ngIf="regCode.errors?.required">
                          Registration code is required!
                        </p>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="form-control-group">
                  <div class="input-container input-text">
                    <img src="assets/images/icons/in.png" class="img-fluid input-icon">
                    <div class="floating-label">
                      <input nbInput id="input-mobile" class="input-mobile floating-input" name="mobile" type="text" pattern="^[6-9]\d{9}$" placeholder=" " fullWidth required #mobile="ngModel" [(ngModel)]="user.mobile">
                      <label class="label custom-label mobile-label" for="input-mobile">Mobile no</label>
                    </div>
                    <span class="unit">+91</span>
                    <ng-container *ngIf="mobile.invalid && mobile.touched">
                      <p class="error-message" *ngIf="mobile.errors?.required">
                        Number is required!
                      </p>
                      <p class="error-message" *ngIf="mobile.errors?.pattern">
                        Number should be the real one!
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col text-center">
                <button class="submit btn btn-lg register-button" fullWidth status="success" [class.btn-pulse]="submitted" [disabled]="!registerUsingCodeForm.valid">
                  <span class="register-text">Register</span>
                </button>
              </div>
            </div>

            <div class="row shadow-container">
              <div class="mx-auto">
                <div class="form-control-group">
                  <p class="term-condition-text"> By signing up, I agree to Slingshot’s <u><a class="text-link" href="{{ brand_website }}/terms-and-conditions/" target="_blank">Terms of Service</a></u> and <u><a class="text-link" href="{{ brand_website }}/privacy-policy/" target="_blank">Privacy Policy.</a></u></p>
                </div>
              </div>
            </div>

          </form>
          <section class="another-action text-center mt-4" aria-label="Sign in">
            <p class="sign-in-text">Already Registered? <a class="text-link" routerLink="/"><u> SIGN IN. </u></a>
            </p>
          </section>
        </nb-step>

        <!-- Second Step -->
        <nb-step [stepControl]="otpForm" label="Otp">
          <p class="otp-subtitle">An OTP was sent to your mobile number.</p>
          <nb-card>
            <form #otpForm="ngForm" class="step-container" aria-labelledby="title" method="POST" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="col">
                  <div class="form-control-group">
                    <div class="input-container">
                      <div class="floating-label">
                        <input class="floating-input" nbInput id="input-otpCode" name="otpCode" type="text" autocapitalize="none" placeholder=" " fullWidth required #otpCode="ngModel" [(ngModel)]="user.otpCode">
                        <label class="label custom-label" for="input-otpCode">Please enter OTP</label>
                      </div>
                      <ng-container *ngIf="otpCode.invalid && otpCode.touched">
                        <p class="error-message" *ngIf="otpCode.errors?.required">
                          Otp is required!
                        </p>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="form-control-group">
                    <div (click)="verifyRegCode()">
                      <p class="term-condition-text"><u>Didn’t receive an OTP? Resend</u></p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col text-center">
                  <button class="submit btn btn-lg register-button" fullWidth status="success" [class.btn-pulse]="submitted" [disabled]="!otpForm.valid">
                    <span class="register-text">Submit</span>
                  </button>
                </div>
              </div>
            </form>
          </nb-card>
        </nb-step>

        <!-- Third Step -->
        <nb-step [stepControl]="" label="Otp">
          <div class="row register-section">
            <div class="register-form-wrapper">
              <nb-card [nbSpinner]="loading" nbSpinnerStatus="success" nbSpinnerSize="large" nbSpinnerMessage=""></nb-card>
              <div>
                <div class="container register-form">
                  <form #form="ngForm" aria-labelledby="title" method="POST">
                    <div class="row">
                      <div class="col">
                        <div class="form-control-group password-sec">
                          <div class="input-container input-text">
                            <img src="assets/images/icons/lock.png" class="img-fluid input-icon">
                            <span [class]="_show ? 'fa fa-eye input-icon rightside-icon' : 'fa fa-eye-slash input-icon rightside-icon'" (click)="togglepwdType()"></span>
                            <div class="password-wrapper">
                              <img src="assets/images/icons/info.png" [class]="(isFocus && !password.errors?.required) ? 'img-fluid input-icon icon-info password-box-hide' : 'img-fluid input-icon icon-info password-box-show'" (click)="OpenPasswordBox()">
                              <img src="assets/images/icons/info-purple.png" [class]="(isFocus && !pass_pattern && !password.errors?.required  || clickedPasswordIcon) ? 'img-fluid input-icon icon-info password-box-show' : 'img-fluid input-icon icon-info password-box-hide'" (click)="OpenPasswordBox()">
                              <img src="assets/images/icons/check-circle.png" [class]="(isFocus && pass_pattern) ? 'img-fluid input-icon icon-info password-box-show' : 'img-fluid input-icon icon-info password-box-hide'">
                              <!-- Passsword verification box -->
                              <div class="password">
                                <div [class]="(isFocus && !pass_pattern && !password.errors?.required || clickedPasswordIcon) ? 'password-box password-box-show' : 'password-box password-box-hide'">
                                  <h4 class="password-heading">Password must</h4>
                                  <div class="password-condition">
                                    <div class="password-validation">
                                      <i [class]="isNumber ? 'fa fa-check-circle fa-check-circle-color': 'fa fa-check-circle'" aria-hidden="true"></i>
                                      Have 1 Number
                                    </div>
                                    <div class="password-validation">
                                      <i [class]="isSpecialChar ? 'fa fa-check-circle fa-check-circle-color': 'fa fa-check-circle'" aria-hidden="true"></i>
                                      Have 1 special character
                                    </div>
                                    <div class="password-validation">
                                      <i [class]="isUpperCase ? 'fa fa-check-circle fa-check-circle-color': 'fa fa-check-circle'" aria-hidden="true"></i>
                                      Have 1 uppercase
                                    </div>
                                    <div class="password-validation">
                                      <i [class]="isLowerCase ? 'fa fa-check-circle fa-check-circle-color': 'fa fa-check-circle'" aria-hidden="true"></i>
                                      Have 1 lowercase
                                    </div>
                                    <div class="password-validation">
                                      <i [class]="isLength ? 'fa fa-check-circle fa-check-circle-color': 'fa fa-check-circle'" aria-hidden="true"></i>
                                      Have 8 characters minimum
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!--Passsword verification box -->
                            <div class="floating-label">
                              <input nbInput [(ngModel)]="user.password" #password="ngModel" [type]="_pwdType" id="input-password" autocapitalize="none" class="password-field floating-input" name="password" placeholder=" " fullWidth [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'" required minlength="8" maxlength="50" [pattern]="pattern" [attr.aria-invalid]="password.invalid && password.touched ? true : null" (input)="onPasswordInput($event.target.value)">
                              <label class="label custom-label" for="input-password">Create Password</label>
                            </div>
                            <ng-container *ngIf="password.invalid && password.touched">
                              <p class="error-message" *ngIf="password.errors?.required">
                                Password is required!
                              </p>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col text-center">
                        <button class="submit btn btn-lg register-button" fullWidth status="success" (click)="enterPassword(form)" [disabled]="submitted || !form.valid" [class.btn-pulse]="submitted">
                          <span class="register-text">Get Started!</span>
                        </button>
                      </div>
                    </div>
                    <div class="row shadow-container">
                      <div class="col">
                        <div class="form-control-group">
                          <p class="term-condition-text"> By signing up, I agree to Slingshot’s <u><a class="text-link" href="{{ brand_website }}/terms-and-conditions/" target="_blank">Terms of Service</a></u> and <u><a class="text-link" href="{{ brand_website }}/privacy-policy/" target="_blank">Privacy Policy.</a></u></p>
                        </div>
                      </div>
                    </div>

                  </form>

                  <section class="another-action text-center mt-4" aria-label="Sign in">
                    <p class="sign-in-text">Already Registered? <a class="text-link" routerLink="/login"><u> SIGN IN.
                        </u></a>
                    </p>
                  </section>
                </div>
              </div>

            </div>
          </div>

        </nb-step>

      </nb-stepper>
    </div>
  </div>
</div>
