<div class="header-container" [class.left]="position === 'normal'" [class.right]="position === 'inverse'">
  <div (click)="toggleSidebar()" *ngIf="globals.sidebar_status">
    <i class="fa fa-bars" aria-hidden="true"></i>
  </div>
  <div class="logo-containter" *ngIf="isAvailable === true">
    <a routerLink="/admin/dashboard">
      <div class="logo">
        <img src="assets/images/logo/slingshot-logo.png">
      </div>
    </a>
  </div>
  <div class="logo-containter" *ngIf="isAvailable === false">
    <a href="{{ brand_website }}">
      <div class="logo" [ngClass]="isAvailable === false ? 'login-logo' : ''">
        <img src="assets/images/logo/slingshot-login-logo.png">
      </div>
    </a>
  </div>
</div>
<div class="header-container" [ngClass]="isAvailable === false ? 'header-wrapper' : ''">
  <div *ngIf="isAvailable === true">
    <nb-actions size="medium" [class.right]="position === 'normal'" [class.left]="position === 'inverse'">
      <nb-action [ngClass]="'header-icons border-0'">
        <img [nbContextMenu]="userMenu" nbContextMenuTag="context-menu" src="assets/images/icons/profile.png">
      </nb-action>
      <!-- <nb-action [ngClass]="'header-icons border-0'">
        <img [nbContextMenu]="helpMenu" src="assets/icons/help.png">
        </nb-action>
        <nb-action [ngClass]="'header-icons border-0'">
        <nb-badge class="notification-counts" position="top" status="danger" text="1"></nb-badge>
        <img src="assets/icons/notification.png">
        </nb-action> -->
      <nb-action [ngClass]="'header-icons border-0 shadow-divider'">
        <img src="assets/images/icons/vertical-shadow.png">
      </nb-action>
      <nb-action [ngClass]="'border-0 hide-on-mobile'" *ngIf="!prof_id">
        <a (click)="unlockBtn()">
          <div class="unlock-btn">UPGRADE PLAN</div>
        </a>
      </nb-action>
    </nb-actions>
  </div>
  <div *ngIf="isAvailable === false">
    <nb-actions size="medium" [class.right]="position === 'normal'" [class.left]="position === 'inverse'"
      class="hide-on-mobile desktop-header">
      <nb-action *ngFor="let s of reverse_menu">
        <a [href]="s.url" *ngIf="s.title != 'Choose Your Exam'">
          {{s.title}}
        </a>
        <a *ngIf="s.title == 'Choose Your Exam'" [nbContextMenu]="subMenu" nbContextMenuTrigger="hover">
          {{s.title}} <i class="fa fa-angle-down" aria-hidden="true"></i>
        </a>
      </nb-action>
    </nb-actions>
    <!-- <div class="hide-on-desktop login-pg-menu">
      <i class="fa fa-bars" aria-hidden="true" (click)="clickSideMenu()"></i>
      <div class="overlay" *ngIf="show_sideMenu" (click)="clickSideMenu()"></div>
      <ul class="list-inline side-menu" *ngIf="show_sideMenu" >
        <li (click)="clickExamMenu()">
          <a>
            Entrance Exams
            <i class="fa fa-angle-down" aria-hidden="true" *ngIf="!show_examMenu"></i>
            <i class="fa fa-angle-up" aria-hidden="true" *ngIf="show_examMenu"></i>
          </a>
          <ul class="list-inline" *ngIf="show_examMenu"  (click)="clickSideMenu()">
            <li>
              <a href="{{ brand_website }}/entrance-exam-iit-jee/">IIT - JEE MAIN</a>
            </li>
            <li>
              <a href="{{ brand_website }}/entrance-exam-mh-cet/">MH - CET</a>
            </li>
            <li>
              <a href="{{ brand_website }}/entrance-exam-neet/">NEET</a>
            </li>
          </ul>
        </li>
        <li  (click)="clickSideMenu()">
          <a href="{{ brand_website }}/why-choose-us/">
            Why Slingshot
          </a>
        </li>
        <li  (click)="clickSideMenu()">
          <a href="{{ brand_website }}/pricing-plans/">
            Pricing Plans
          </a>
        </li>
        <li  (click)="clickSideMenu()">
          <a href="{{ brand_website }}/about-us/">
            About Us
          </a>
        </li>
        <li (click)="clickSideMenu()">
          <a href="{{ brand_website }}/contact-us/">
            Contact Us
          </a>
        </li>
      </ul>
    </div> -->
  </div>
</div>
