<nb-alert *ngIf="showMessages.error && errors?.length && !submitted" status="danger" role="alert" closable (close)="errors = []">
  Oh snap! {{ errors[0] }}
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length && !submitted" status="success"  role="alert" closable (close)="messages = []">
    Hooray! {{ messages[0] }}
</nb-alert>

<div class="row register-section" *ngIf="!isLoggedIn || google_email">
  <div class="register-form-wrapper col-xl-4 col-lg-8 col-md-8 col-sm-8 ml-auto mr-auto">
      <div *ngIf="!isLoggedIn || google_email">
        <h1 id="title" *ngIf="!this.route.snapshot.queryParamMap.get('em')" class="register-title">Sign Up for Slingshot Students!</h1>
        <p *ngIf="this.route.snapshot.queryParamMap.get('em')" class="register-subtitle">
        <sub class="name">Hi {{user.fullName}},</sub><br>
        One last step !!! SET YOUR PASSWORD <br>
        <sub class="hint">(Hint : It could be your favorite symbols, formula or even a chemical compound!)</sub><br>
        <sub class="tag-line">GET SET FOR YOUR PREP, <br class="break">  AND DON'T WORRY ABOUT THE REST ! </sub><br>
        <sub class="tag-line">We’ve got your back ! </sub> <img src="assets/images/icons/use-on-white.png" class="img-fluid input-icon">
        </p>
        <p *ngIf="!this.route.snapshot.queryParamMap.get('em')" class="sign-in-text">Please fill in the following details to Sign up (Already Registered? <a class="text-link" routerLink="/"><u> SIGN IN
        </u></a>)</p>

        <div class="container register-form">
          <form #form="ngForm" aria-labelledby="title" method="POST">
            <!-- Name -->
            <div class="row">
              <div class="col">
                <div class="form-control-group">
                  <div class="input-container input-text">
                    <img src="assets/images/icons/full-name.png" class="img-fluid input-icon">
                    <div class="floating-label">
                      <input class="floating-input" nbInput [(ngModel)]="user.fullName" #fullName="ngModel"
                        id="input-full-name" name="fullName" placeholder=" " fullWidth required
                        [status]="fullName.dirty ? (fullName.invalid || !fullName.value ? 'danger' : 'success') : 'basic'"
                        [attr.aria-invalid]="fullName.invalid && fullName.touched ? true : null" [attr.disabled]="google_email ? 'disabled' : null" autocapitalize="none">
                      <input type="hidden" class="floating-input" nbInput [(ngModel)]="user.google_email "
                        #googleUser="ngModel" id="google_email" name="googleUser" placeholder=" " fullWidth required *ngIf="google_email">
                        <label class="label custom-label" for="input-email">Name</label>
                    </div>
                    <ng-container *ngIf="fullName.invalid && fullName.touched">
                      <p class="error-message" *ngIf="fullName.errors?.required">
                        Full name is required!
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <!-- Mobile -->
            <div class="row">
              <div class="col">
                <div class="form-control-group">
                  <div class="input-container input-text">
                    <img src="assets/images/icons/in.png" class="img-fluid input-icon">
                    <div class="floating-label">
                      <input nbInput [(ngModel)]="user.mobile" #mobile="ngModel" id="input-mobile"
                        [ngClass]="phone_error ? 'input-mobile floating-input input-error' : 'input-mobile floating-input'" name="mobile" placeholder=" " type="text"
                        (ngModelChange)="phone_changed_manually()"
                        [pattern]="phone_pattern" fullWidth required
                        [status]="mobile.dirty ? (mobile.invalid || !mobile.value ? 'danger' : 'success') : 'basic'"
                        [minlength]="getConfigValue('forms.validation.mobile.minLength')"
                        [maxlength]="getConfigValue('forms.validation.mobile.maxLength')"
                        [attr.aria-invalid]="mobile.invalid && mobile.touched ? true : null">
                      <label class="custom-label mobile-label" for="input-mobile">Mobile no</label>
                    </div>
                    <span class="unit">+91</span>
                    <ng-container *ngIf="(mobile.invalid && mobile.touched) || phone_error">
                      <p class="error-message" *ngIf="mobile.errors?.required">
                        Number is required!
                      </p>
                      <p class="mobile-message" *ngIf="mobile.errors?.pattern || phone_error">
                        Number should be the real one!
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <!-- Email -->
            <div class="row">
              <div class="col">
                <div class="form-control-group">
                  <div class="input-container input-text">
                    <img src="assets/images/icons/email.png" class="img-fluid input-icon">
                    <div class="floating-label">
                      <input nbInput [(ngModel)]="user.email" #email="ngModel" id="input-email"
                        name="email" [pattern]="email_pattern" placeholder=" " fullWidth
                        (ngModelChange)="email_changed_manually()"
                        [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
                        [ngClass]="email_error ? 'floating-input input-error' : 'floating-input'"
                        [required]="getConfigValue('forms.validation.email.required')"
                        [attr.aria-invalid]="email.invalid && email.touched ? true : null" [attr.disabled]="google_email ? 'disabled' : null" autocapitalize="none">
                      <label class="label custom-label" for="input-email">Email ID</label>
                    </div>
                    <ng-container *ngIf="(email.invalid && email.touched) || email_error">
                      <p class="error-message" *ngIf="email.errors?.required">
                        Email is required!
                      </p>
                      <p class="error-message" *ngIf="email.errors?.pattern || email_error">
                        Email should be the real one!
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <!-- Password -->
            <div class="row" *ngIf="!google_email">
              <div class="col">
                <div class="form-control-group password-sec">
                  <div class="input-container input-text">
                    <img src="assets/images/icons/lock.png" class="img-fluid input-icon">
                    <span
                      [class]="_show ? 'fa fa-eye input-icon rightside-icon' : 'fa fa-eye-slash input-icon rightside-icon'"
                      (click)="togglepwdType()"></span>
                    <div class="password-wrapper">
                      <img src="assets/images/icons/info.png"
                        [class]="(isFocus && !password.errors?.required) ? 'img-fluid input-icon icon-info password-box-hide' : 'img-fluid input-icon icon-info password-box-show'"
                        (click)="OpenPasswordBox()">
                      <img src="assets/images/icons/info-purple.png"
                        [class]="(isFocus && !pass_pattern && !password.errors?.required  || clickedPasswordIcon) ? 'img-fluid input-icon icon-info password-box-show' : 'img-fluid input-icon icon-info password-box-hide'"
                        (click)="OpenPasswordBox()">
                      <img src="assets/images/icons/check-circle.png"
                        [class]="(isFocus && pass_pattern) ? 'img-fluid input-icon icon-info password-box-show' : 'img-fluid input-icon icon-info password-box-hide'">
                      <!-- Passsword verification box -->
                      <div class="password">
                        <div
                          [class]="(isFocus && !pass_pattern && !password.errors?.required || clickedPasswordIcon) ? 'password-box password-box-show' : 'password-box password-box-hide'">
                          <h4 class="password-heading">Password must</h4>
                          <div class="password-condition">
                            <div class="password-validation">
                              <i [class]="isNumber ? 'fa fa-check-circle fa-check-circle-color': 'fa fa-check-circle'"
                                aria-hidden="true"></i>
                              Have 1 Number
                            </div>
                            <div class="password-validation">
                              <i [class]="isSpecialChar ? 'fa fa-check-circle fa-check-circle-color': 'fa fa-check-circle'"
                                aria-hidden="true"></i>
                              Have 1 special character
                            </div>
                            <div class="password-validation">
                              <i [class]="isUpperCase ? 'fa fa-check-circle fa-check-circle-color': 'fa fa-check-circle'"
                                aria-hidden="true"></i>
                              Have 1 uppercase
                            </div>
                            <div class="password-validation">
                              <i [class]="isLowerCase ? 'fa fa-check-circle fa-check-circle-color': 'fa fa-check-circle'"
                                aria-hidden="true"></i>
                              Have 1 lowercase
                            </div>
                            <div class="password-validation">
                              <i [class]="isLength ? 'fa fa-check-circle fa-check-circle-color': 'fa fa-check-circle'"
                                aria-hidden="true"></i>
                              Have 8 characters minimum
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--Passsword verification box -->
                    <div class="floating-label">
                      <input nbInput [(ngModel)]="user.password" #password="ngModel" [type]="_pwdType"
                        id="input-password" autocapitalize="none" class="password-field floating-input" name="password" placeholder=" " fullWidth [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'" required minlength="8" maxlength="50" [pattern]="pattern" [attr.aria-invalid]="password.invalid && password.touched ? true : null" (input)="onPasswordInput($event.target.value)">
                      <label class="label custom-label" for="input-password">Create Password</label>
                    </div>
                    <ng-container *ngIf="password.invalid && password.touched">
                      <p class="error-message" *ngIf="password.errors?.required">
                        Password is required!
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <!-- Class -->
            <div class="row">
              <div class="col">
                <div class="form-control-group current-class">
                  <label class="label select-label" for="input-class">Current Class</label>
                  <ul class="row">
                      <input type="radio" id="class2" value="Class XI" [(ngModel)]="user.class" #class="ngModel" name="class" required>
                      <label class="radio lable-wrap ml-2" for="class2">Class XI</label> &nbsp; &nbsp;
                      <input type="radio" id="class3" value="Class XII" [(ngModel)]="user.class" #class="ngModel" name="class" required>
                      <label class="radio lable-wrap ml-2" for="class3">Class XII</label>
                  </ul>

                  <ng-container *ngIf="class.invalid && class.touched">
                    <p class="error-message" *ngIf="class.errors?.required">
                      Please select class.
                    </p>
                  </ng-container>
                </div>
              </div>
            </div>

            <!-- Exam -->
            <div class="row">
              <div class="col">
                <div class="form-control-group select-exam-field">
                  <label class="label select-label" for="input-password">Select Exam
                    <span class="select-range">(Select up to 2)</span></label>
                  <div class="row shadow-container">
                    <div class="subscription-content-box exam-section col-6" *ngFor="let exam of exams; index as i">
                      <fieldset [class]="(exam_array.includes(exam.exam_id))?'rectangle examname-checkbox':'rectangle'">
                        <div class="sub-wrapper">
                          <div class="exam-name" >
                            <label class="exam-title" for="box-{{i}}"><input type="checkbox" id="box-{{i}}" name="exams{{i}}" (change)="checkedState($event, exam.exam_id)" [(ngModel)]="exam.checked" [attr.disabled]="exam_array?.length >= 2 && !exam.checked ?'' : null" />
                              {{ exam.exam_name }}
                            </label>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr class="horizontal-line">

            <!-- Registration Code -->
            <div class="row">
              <div class="col">
                <div class="form-control-group registration-code-wrapper">
                  <label class="label registration-code" for="reg-Code" (click)="onClickDownArrow()"><u>I have a Registration or Discount Code</u></label>
                  <span class="fa fa-angle-down input-icon rightside-icon" (click)="onClickDownArrow()"></span>
                  <div class="input-container input-text" *ngIf="_arrow">
                    <i class="fa fa-tag input-icon"></i>
                    <input nbInput [(ngModel)]="user.regCode" #regCode="ngModel" id="reg-Code" name="regCode"
                      placeholder="Registration or Discount Code (If Any)" fullWidth
                      [status]="regCode.dirty ? (regCode.invalid  ? 'danger' : 'success') : 'basic'"
                      [attr.aria-invalid]="regCode.invalid && regCode.touched ? true : null">
                    <ng-container *ngIf="regCode.invalid && regCode.touched">
                      <p class="regCode-message" *ngIf="regCode.errors?.required">
                        Registration or Discount Code is required!
                      </p>
                      <p class="regCode-message" *ngIf="regCode.errors?.pattern">
                        Registration or Discount Code should be the real one!
                      </p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <!-- Submit -->
            <div class="row">
              <div class="col text-center">
                <button class="submit btn btn-lg register-button" fullWidth status="success" (click)="onSubmit()" [disabled]="submitted || !(form.valid && (exam_array?.length > 0 && exam_array?.length < 3))" [class.btn-pulse]="submitted">
                  <span class="register-text"><i class="fa" [ngClass]="{'fa-spin fa-spinner': loading}"></i> Get Started!</span>
                </button>
              </div>
            </div>

            <!-- Privacy -->
            <div class="row">
              <div class="col">
                <div class="form-control-group">
                  <p class="term-condition-text"> By signing up, I agree to Slingshot’s <u><a class="text-link" href="{{ brand_website }}/terms-and-conditions/" target="_blank">Terms of Service</a></u> and <u><a class="text-link" href="{{ brand_website }}/privacy-policy/" target="_blank">Privacy Policy.</a></u></p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
  </div>
</div>
