import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';

import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';

import { AutoLogoutService } from './auto-logout.service';
import { Globals } from './global';

const urls = ['practice-test', 'prelim-test', 'previous-year'];
declare let gtag: Function;
declare let fbq: Function;

@Component({
    selector: 'ngx-app',
    styleUrls: ['./auth/auth.component.scss'],
    template: `
        <div ngxUiLoaderBlurred>
            <ngx-structure-layout>
                <router-outlet></router-outlet>
            </ngx-structure-layout>
        </div>
        <ngx-ui-loader></ngx-ui-loader>
        <ngx-app-updates-notification *ngIf="toBeShown$"></ngx-app-updates-notification>
    `,
    providers: [AutoLogoutService],
})

export class AppComponent implements OnInit, OnDestroy {
    user: any;
    toBeShown$: boolean = true;
    student_exams: any;

    constructor(
        private authService: NbAuthService,
        public router: Router,
        private cookieService: CookieService,
        private autoLogout: AutoLogoutService,
        private globals: Globals,
    ) {
        // Auth
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                if (token.isValid()) {
                    this.user = token.getPayload();
                    this.cookieService.delete('rememberMe', '/');
                    if (this.user.remember_token) {
                        this.cookieService.set('rememberMe', this.user.remember_token, 30, '/');
                    }
                }
            });

        // Google Analytics
        const script = document.createElement('script');
        script.async = true;
        script.type = 'text/javascript';
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaTrackingCode;
        document.head.appendChild(script);

        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                let user_id = 'guest-user-student';
                let user_email = 'guest@slingshotlearn.com';
                if (this.user !== undefined && this.user.StudentProfile !== undefined) {
                    user_id = 'user-' + this.user.id + '-student-' + this.user.StudentProfile.student_id;
                    user_email = this.user.email;
                }
                try {
                    // Track Google Analytics
                    gtag('config', environment.gaTrackingCode, {
                        'page_path': event.urlAfterRedirects,
                        'user_id': user_id,
                        'custom_map': {
                            'dimension1': 'custom_user_id',
                            'dimension2': 'user_email',
                        },
                    });
                    gtag('event', 'custom_user_id_event', { 'custom_user_id': user_id });
                    gtag('event', 'user_email_event', { 'user_email': user_email });
                    gtag('set', {'user_id': user_id}); // Set the user ID using signed-in user_id.

                    // Track Facebook Pixel
                    if (environment.site_state === 'production') {
                        fbq('track', 'PageView');
                    }
                } catch { }

                this.toBeShown$ = !urls.some(v => this.router.url.includes(v));

                if (this.router.url === '/logout') {
                    this.hideHeaderSidebar();
                }
            }
        });

        if (!localStorage.getItem('isTimeout') && !localStorage.getItem('lastAction')) {
            localStorage.setItem('isTimeout', 'false');
            this.autoLogout.initListener();
            this.autoLogout.initInterval();
        }
    }

    ngOnInit() {
        this.hideHeaderSidebar();
    }

    ngOnDestroy() {
        this.autoLogout.removeListener();
        this.autoLogout.removeInterval();
    }

    hideHeaderSidebar() {
        this.globals.sidebarStatus(false);
        this.globals.headerStatus(false);
    }


}
