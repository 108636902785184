import {
    Component,
    OnInit,
    ChangeDetectorRef,
    Inject,
    OnDestroy,
} from '@angular/core';
import { NbRegisterComponent, NbAuthJWTToken, NB_AUTH_OPTIONS, getDeepFromObject, NbAuthService } from '@nebular/auth';
import { NbAuthSocialLink } from '@nebular/auth/auth.options';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NbAuthResult } from '@nebular/auth/services/auth-result';
import { ApiService } from '../../api.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../../global';
import { environment } from '../../../environments/environment';
import swal from 'sweetalert2';
declare let fbq: Function;

@Component({
    selector: 'ngx-register',
    templateUrl: './register.component.html',
    styleUrls: ['register.component.scss'],
})

export class NgxRegisterComponent extends NbRegisterComponent implements OnInit, OnDestroy {
    show: any;
    isLoggedIn: boolean = false;
    loading: boolean = false;
    redirectDelay: number = 0;
    showMessages: any = {};
    strategy: string = '';
    submitted: boolean = false;
    errors: string[] = [];
    messages: string[] = [];
    user: any = {};
    socialLinks: NbAuthSocialLink[] = [];
    brand_website: string = environment.brand_website;
    exams: any;
    exam_array: any = [];
    counter: any = 0;
    _show: boolean = false;
    _arrow: boolean = false;
    _pwdType = 'password';
    subscription: Subscription;
    recentToken: string = '';
    package_slug: any;
    isFocus: boolean = false;
    isLength: boolean = false;
    isNumber: boolean = false;
    isSpecialChar: boolean = false;
    isUpperCase: boolean = false;
    isLowerCase: boolean = false;
    pass_pattern: boolean = false;
    clickedPasswordIcon: boolean = false;
    google_email: any;
    pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    email_pattern = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
    phone_pattern = /^[6-9]\d{9}$/;
    email_error: boolean = false;
    phone_error: boolean = false;

    constructor(
        protected service: NbAuthService,
        @Inject(NB_AUTH_OPTIONS) protected options = {},
        protected cd: ChangeDetectorRef,
        protected router: Router,
        public data: AuthService,
        private authService: NbAuthService,
        private api: ApiService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private route: ActivatedRoute,
        private globals: Globals,
    ) {
        super(service, options, cd, router);
        this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
            if (token.isValid()) {
                this.user = token.getPayload();
            }
        });

        this.package_slug = this.route.snapshot.queryParamMap.get('package_slug');
        this.redirectDelay = this.getConfigValue('forms.register.redirectDelay');
        this.showMessages = this.getConfigValue('forms.register.showMessages');
        this.strategy = this.getConfigValue('forms.register.strategy');
        this.socialLinks = this.getConfigValue('forms.login.socialLinks');

        this.getallExamsData();

        /*
            Handle n, em, mo, cl, x & r
            http://localhost:4200/register?n=darshan%20gada&em=test@gmail.com&mo=9029208698&cl=Class%20XII&x=2,3,4&r=ABC202010
        */
        this.user.fullName = this.route.snapshot.queryParamMap.get('n');
        this.user.email = this.route.snapshot.queryParamMap.get('em');
        this.user.mobile = this.route.snapshot.queryParamMap.get('mo');
        this.user.class = this.route.snapshot.queryParamMap.get('cl');
        const exams = this.route.snapshot.queryParamMap.get('x');
        if (exams) this.exam_array = exams.split(',').map(Number);
        if (this.exam_array.length > 2) this.exam_array = this.exam_array.splice(0, 2);
        this.user.regCode = this.route.snapshot.queryParamMap.get('r');

        if (localStorage.getItem('google_email') && history.state.data !== undefined) {
            this.user.email = localStorage.getItem('google_email');
            this.user.google_email = localStorage.getItem('google_email');
            this.google_email = localStorage.getItem('google_email');
            this.user.fullName = localStorage.getItem('google_name');
        }
    }

    ngOnInit() {
        this.isLoggedIn = this.data.isLoggedIn;
        this.globals.sidebarStatus(false);
        this.globals.headerStatus(true);
        if (this.user.email) {
            this.email_error = !this.email_pattern.test(this.user.email);
        }
        if (this.user.mobile) {
            this.phone_error = !this.phone_pattern.test(this.user.mobile);
        }
    }

    onPasswordInput(e) {
        this.isFocus = true;
        this.isLength = (e.length >= 8);
        this.isNumber = /[0-9]/.test(e);
        this.isLowerCase = /[a-z]/.test(e);
        this.isUpperCase = /[A-Z]/.test(e);
        this.isSpecialChar = /[!@#*\$%\^&]/.test(e);
        this.pass_pattern = this.isLength && this.isNumber && this.isLowerCase && this.isUpperCase && this.isSpecialChar;
    }

    async onSubmit() {
        if (this.package_slug) {
            await this.api.getPackageBySlug(this.package_slug).subscribe(data => {
                if (data['package']) {
                    this.globals.packageName(this.package_slug);
                    if (this.package_slug === 'free') {
                        this.user['package_slug'] = this.package_slug;
                    }
                } else {
                    window.location.href = environment.brand_website + '/pricing-table/';
                }
            });
        }
        this.subscription = await this.recaptchaV3Service
            .execute('register')
            .subscribe(token => this.onTokenGen(token));
    }

    onTokenGen(t) {
        this.user['recaptcha_token'] = t;
        this.user['selected_exams'] = this.exam_array;
        if (!t) {
            alert('Recaptcha error');
        } else {
            this.register();
        }
    }

    register(): void {
        this.loading = true;
        setTimeout(() => (this.loading = false), 2000);
        const package_url = '/register/plan/' + this.package_slug;
        if (this.router.url !== package_url) {
            // setting free as a default package for all regsitering users
            this.user['package_slug'] = 'free';
        }

        this.errors = this.messages = [];
        this.submitted = true;

        this.service
            .register(this.strategy, this.user)
            .subscribe((result: NbAuthResult) => {
                this.submitted = false;

                let redirect = result.getRedirect();
                if (result.isSuccess()) {
                    this.messages = result.getMessages();
                    swal.fire({
                        title: 'Hooray!',
                        text: this.messages[0],
                        type: 'success',
                    });
                    if (this.package_slug && this.package_slug !== 'free') {
                        redirect = '/admin/payment';
                    }
                } else {
                    this.errors = result.getErrors();
                    swal.fire({
                        title: 'Error',
                        text: this.errors[0],
                        type: 'error',
                        showCancelButton: true,
                    });
                    if (this.router.url === package_url) {
                        redirect = package_url;
                    }
                }

                // Try Track Registration to Facebook Pixel
                if (environment.site_state === 'production') {
                    try {
                        const userInfo = {
                            email: this.user.email || '',
                        };
                        fbq('trackCustom', 'RegistrationFormFilled', userInfo);
                    } catch { }
                }

                setTimeout(() => {
                    return this.router.navigateByUrl(redirect);
                }, this.redirectDelay);

                this.cd.detectChanges();
            });
    }

    getallExamsData() {
        this.api.getallExamsData().subscribe(data => {
            this.exams = data['allExamsData'];
            // Remove GUJ-CET Temporarily
            this.exams = this.exams.filter(item => item.exam_id !== 3);
        });
    }

    checkedState(e, checkboxValue) {
        if (e.target.checked) {
            this.exam_array.push(checkboxValue);
        } else {
            this.exam_array.splice(this.exam_array.indexOf(checkboxValue), 1);
        }
    }

    togglepwdType() {
        this._show = !this._show;
        this._pwdType = this._show ? 'text' : 'password';
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

    onClickDownArrow() {
        this._arrow = !this._arrow;
    }

    ngOnDestroy() {
        if (this.subscription !== undefined) {
            this.subscription.unsubscribe();
        }
    }

    OpenPasswordBox() {
        this.clickedPasswordIcon = !this.clickedPasswordIcon;
    }

    email_changed_manually() {
        this.email_error = false;
    }

    phone_changed_manually() {
        this.phone_error = false;
    }
}
