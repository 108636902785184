import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class Globals {
    exam: any = 'exam';
    exam_date: any;
    default: any;
    defaultExam: any;
    sidebar_status: any = true;
    header_status: any = true;
    testCount: any = 0;
    timeSpentCount: any = 0;
    totalQuestionAttempted: any = 0;
    set_id: any;
    test_entry_response: any;
    test_type_id: any;
    fav_sub_id: any;
    selected_exam_date;
    selected_date_format;
    selected_exam_name;
    selected_exam_id;
    selected_menu: any;
    monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'];
    data = [];
    selected_year;
    selected_exam;
    student_id;
    logged_in;
    package_slug: any;
    isFirstVisit: any = false;
    sets: any;
    visited_question: any;
    stud_exams: any;

    private messageSource = new BehaviorSubject(this.default);
    currentMessage = this.messageSource.asObservable();

    private examSource = new BehaviorSubject(this.defaultExam);
    currentExam = this.examSource.asObservable();

    constructor() { }

    selectedExamId(value: any) {
        this.messageSource.next(value);
        this.selected_exam_id = value;
        localStorage.setItem('selected_exam_id', this.selected_exam_id);
    }

    visitedQuestion(value: any) {
        this.messageSource.next(value);
        this.visited_question = value;
        localStorage.setItem('visited_question', value);
        this.visited_question = localStorage.getItem('visited_question');
    }

    changeMessage(message: any) {
        this.messageSource.next(message);
    }

    defaultMessage(message: any) {
        this.messageSource.next(message);
    }

    async currentExamSet(examData: any) {
        this.examSource.next(examData);
        this.selected_exam_name = examData['entrance_exam'].exam_name;
        if (examData['entrance_exam']['exam_dates'] !== undefined && examData['entrance_exam']['exam_dates'].length !== 0) {
            this.selected_exam_date = examData['entrance_exam']['exam_dates'][0].exam_date;
        }
        this.selected_exam_id = examData['entrance_exam'].exam_id;
        this.selected_date_format = new Date(this.selected_exam_date);
        this.selected_date_format = this.selected_date_format.getDate() + ' ' + this.monthNames[this.selected_date_format.getMonth()];
        let j = 0;
        this.totalQuestionAttempted = 0;
        this.timeSpentCount = 0;
        this.testCount = 0;
        await this.data.forEach(element => {
            const tc = new Date(element.created_at);
            const tu = new Date(element.updated_at);
            const tsub = Math.abs(tc.getTime() - tu.getTime()) / 1000;
            if (this.selected_exam_name === this.data[j]['exam_name']) {
                this.timeSpentCount = this.timeSpentCount + tsub;
                this.totalQuestionAttempted = this.totalQuestionAttempted + element.answered_questions;
                this.testCount = this.testCount + 1;
            }
            j++;
        });
        this.timeSpentCount = Math.floor(this.timeSpentCount / 60) % 60;
    }

    sidebarStatus(value: any) {
        this.messageSource.next(value);
        this.sidebar_status = value;
    }

    headerStatus(value: any) {
        this.messageSource.next(value);
        this.header_status = value;
    }

    changeFavSubId(message: any) {
        this.fav_sub_id = message;
        localStorage.setItem('fav_sub_id', this.fav_sub_id);
    }

    loggedIn(value: any) {
        this.messageSource.next(value);
        this.logged_in = value;
        localStorage.setItem('isLoggedIn', this.logged_in);
    }

    packageName(value: any) {
        this.messageSource.next(value);
        this.package_slug = value;
        localStorage.setItem('package_slug', this.package_slug);
    }

    changePlanButton(value: any) {
        this.messageSource.next(value);
        this.isFirstVisit = value;
        localStorage.setItem('isPlanFirstVisit', this.isFirstVisit);
    }

    CommanSetFunctionalLoop(sets, dataArray) {
        this.sets = sets;
        this.sets.forEach((element, i) => {
            const arrySearch = dataArray.filter(x => x.test_set_id === Number(element['test_set_id']));

            this.sets[i]['test_summary_id'] = !arrySearch.length ? null : arrySearch[0]['student_test_summary_id'];

            this.sets[i]['answered_questions'] = !arrySearch.length ? 0 : arrySearch[0]['answered_questions'];

            this.sets[i]['time_taken'] = !arrySearch.length ? 0 : arrySearch[0]['time_taken'];

            this.sets[i]['completion'] = !arrySearch.length ? false : arrySearch[0]['status'];

            this.sets[i]['score'] = !arrySearch.length ? false : arrySearch[0]['score'];

            if (i === 0) {
                this.sets[i]['status'] = !arrySearch.length ? false : true;
                this.sets[i]['resume'] = !arrySearch.length ? false : !arrySearch[0]['status'];
            } else if (this.sets[i - 1]['status'] && !this.sets[i - 1]['resume'] && this.sets[i - 1]['completion']) {
                this.sets[i]['status'] = true;
                this.sets[i]['resume'] = !arrySearch.length ? false : !arrySearch[0]['status'];
            } else {
                this.sets[i]['status'] = !arrySearch.length ? false : true;
                this.sets[i]['resume'] = !arrySearch.length ? false : !arrySearch[0]['status'];
            }
            i++;
        });
        return this.sets;
    }

    changedMenu(value: any) {
        this.messageSource.next(value);
        this.selected_menu = value;
        localStorage.setItem('selected_menu', this.selected_menu);
    }

    convertTime(time) {
        let hr = '00';
        let min = '00';
        let secs = '00';
        if (time) {
            hr = (time.split(':')[0] > 0) ? time.split(':')[0] + ' hr ' : '';
            min = (time.split(':')[1] > 0 || time.split(':')[0] > 0) ? time.split(':')[1] + ' min ' : '';
            secs = (time.split(':')[2] > 0 || time.split(':')[1] > 0 || time.split(':')[0] > 0) ? time.split(':')[2] + ' secs ' : '';
        }
        return hr + ' ' + min + ' ' + secs;
    }

    scroll(element: any) {
        element.scrollIntoView({ behavior: 'smooth' });
    }

    encodeParam(obj) {
        return btoa(JSON.stringify(obj));
    }

    decodeParam(param) {
        return JSON.parse(atob(param));
    }

    practiceParam(exam_id, sub_id, chapt_id, given_test_summary, step) {
        const obj = {
            'exam_id': exam_id,
            'sub': sub_id,
            'chapt_id': chapt_id,
            'test_summary': given_test_summary,
            'step': step,
        };
        return btoa(JSON.stringify(obj));

    }

    subjectParam(exam_id, sub_id, given_test_summary, step) {
        const obj = {
            'exam_id': exam_id,
            'sub': sub_id,
            'test_summary': given_test_summary,
            'step': step,
        };
        return btoa(JSON.stringify(obj));

    }

    prelimParam(exam_id, given_test_summary, step) {
        const obj = {
            'exam_id': exam_id,
            'test_summary': given_test_summary,
            'step': step,
        };
        return btoa(JSON.stringify(obj));
    }

    preParam(exam_id, given_test_summary, set, step) {
        const obj = {
            'exam_id': exam_id,
            'test_summary': given_test_summary,
            'set': set,
            'step': step,
        };
        return btoa(JSON.stringify(obj));
    }

    date_diff(start_date, end_date) {
        return Math.round((end_date - start_date) / (1000 * 60 * 60 * 24));
    }

    percentage(num, per, type) {
        let amount = 0;
        if (type === 'percent') {
            amount = num - ((num * per) / 100);
        } else {
            amount = num - per;
        }

        return (amount >= 0) ? amount : 0;
    }
}
