import { PreloadAllModules, ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, LoginGuard } from './auth/auth.guard';

import { NgxLoginComponent } from './auth/login/login.component';
import { NgxLogoutComponent } from './auth/logout.component';
import { NgxRegisterComponent } from './auth/register/register.component';
import { NgxRegisterCodeComponent } from './auth/register-code/register-code.component';
import { NbOAuth2LoginComponent } from './auth/nb-oauth2-login/nb-oauth2-login.component';
import { NbOAuth2CallbackComponent } from './auth/nb-oauth2-callback/nb-oauth2-callback.component';
import { RequestPasswordComponent } from './auth/request-password/request-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

const routes: Routes = [
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        component: NgxLoginComponent,
        canActivate: [LoginGuard],
    },
    {
        path: 'logout',
        component: NgxLogoutComponent,
    },
    {
        path: 'register',
        component: NgxRegisterComponent,
        canActivate: [LoginGuard],
    },
    {
        path: 'register/plan/:package_slug',
        component: NgxRegisterComponent,
        canActivate: [LoginGuard],
    },
    {
        path: 'register-using-code/:reg_code',
        component: NgxRegisterCodeComponent,
        canActivate: [LoginGuard],
    },
    {
        path: 'google',
        component: NbOAuth2LoginComponent,
    },
    {
        path: 'callback',
        component: NbOAuth2CallbackComponent,
    },
    {
        path: 'request-password',
        component: RequestPasswordComponent,
        canActivate: [LoginGuard],
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate: [LoginGuard],
    },
    {
        path: '',
        component: NgxLoginComponent,
        canActivate: [LoginGuard],
    },
    { path: '**', redirectTo: 'admin' },
];

const config: ExtraOptions = {
    useHash: false,
    preloadingStrategy: PreloadAllModules,
    // enableTracing: true, // For Debugging
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})

export class AppRoutingModule {
}
