import { Component, OnInit, Inject } from '@angular/core';
import { NbRequestPasswordComponent, NB_AUTH_OPTIONS, NbAuthService } from '@nebular/auth';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../../global';
import { DataService } from '../../admin/data.service';
@Component({
    selector: 'ngx-request-password',
    templateUrl: './request-password.component.html',
    styleUrls: ['./request-password.component.scss'],
})
export class RequestPasswordComponent extends NbRequestPasswordComponent implements OnInit {

    constructor(
        protected service: NbAuthService,
        @Inject(NB_AUTH_OPTIONS) protected options: {},
        protected cd: ChangeDetectorRef,
        protected router: Router,
        private globals: Globals,
        private dataService: DataService,
    ) {
        super(service, options, cd, router);
    }

    ngOnInit() {
        this.globals.sidebarStatus(false);
        this.globals.headerStatus(true);
    }

    requestPass(): void {
        const _this = this;
        this.errors = this.messages = [];
        this.submitted = true;
        this.service.requestPassword(this.strategy, this.user).subscribe(function (result) {
            _this.submitted = false;
            if (result.isSuccess()) {
                _this.messages = result.getMessages();
                _this.setMsg(_this.messages, 'success');
            } else {
                _this.errors = result.getErrors();
                _this.setMsg(_this.errors, 'danger');
            }
            const redirect = result.getRedirect();
            if (redirect) {
                setTimeout(function () {
                    return _this.router.navigateByUrl(redirect);
                }, _this.redirectDelay);
            }
            _this.cd.detectChanges();
        });
    }

    setMsg(msg, type) {
        this.dataService.set_msg(msg, type);
    }
}
