import { Component, Input } from '@angular/core';

@Component({
    selector: 'ngx-modal-window',
    template: `
    <div class="modal fade show">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ng-content></ng-content>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary">Save changes</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  `,
    styles: [`
    .modal {
      display: block;
    }
  `],
})

export class ModalWindowComponent {
    @Input() title: string = 'Modal title';
}
