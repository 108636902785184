import { Component, Inject } from '@angular/core';
import { NbLogoutComponent, NB_AUTH_OPTIONS, NbAuthService } from '@nebular/auth';
import { AuthService } from '../auth/auth.service';
import { Globals } from '../global';
import { Observable, fromEvent, Subscription } from 'rxjs';

import { Router } from '@angular/router';

@Component({
    selector: 'ngx-logout',
    template: `
    <div class="logout-p">
        <div class="logout-m">
            <img src="assets/images/logo/slingshot-logo.png"><br/>
            <p>Logging out...</p>
        </div>
    </div>
    `,
    styles: [`.logout-p {
        position: relative;
        height: 100%;
        text-align: center;
      }
      .logout-m {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .logout-m p {
        margin-top: 10px;
        color: #5a53ff;
    }`],
})

export class NgxLogoutComponent extends NbLogoutComponent {
    onlineEvent: Observable<Event>;
    offlineEvent: Observable<Event>;
    subscriptions = new Subscription();
    isAppOnline: boolean = window.navigator.onLine;
    constructor(
        protected service: NbAuthService,
        @Inject(NB_AUTH_OPTIONS) protected options = {},
        protected router: Router,
        private authServiceCustom: AuthService,
        public globals: Globals,
    ) {
        super(service, options, router);
        this.onlineEvent = fromEvent(window, 'online');
        this.offlineEvent = fromEvent(window, 'offline');
        this.subscriptions.add(
            this.onlineEvent.subscribe(() => this.isAppOnline = true),
        );

        this.subscriptions.add(
            this.offlineEvent.subscribe(() => {
                this.isAppOnline = false;
            }),
        );

        if (!this.isAppOnline) {
            this.authServiceCustom.isLoggedIn = false;
            this.globals.loggedIn(false);
            this.router.navigate(['/login']);
        }
    }
}
