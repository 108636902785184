import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { CryptoService } from './crypto.service';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const apiUrl = `${environment.apiUrl}`;
const googleUrl = 'https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=';


@Injectable({
    providedIn: 'root',
})
export class ApiService {
    constructor(private http: HttpClient,
        private cryptopService: CryptoService,
    ) { }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else if (!environment.production) {
            console.error(
                `Went ${error.status}`,
                error.error,
            );
        }
        return throwError(error.error);
    }

    private extractData(res: Response) {
        const body = res;
        return body || {};
    }

    getDataUser() {
        return this.getReq(`${apiUrl}/user`);
    }

    verifyToken(data) {
        return this.postReq(`${apiUrl}/users/tokenVerify`, data);
    }

    onLogout(data) {
        return this.postReq(`${apiUrl}/users/logout`, data);
    }

    getUserTestSummaries() {
        return this.getReq(`${apiUrl}/user/test_summaries`);
    }

    getUserProfile() {
        return this.getReq(`${apiUrl}/user/profile`);
    }

    getUserContact() {
        return this.getReq(`${apiUrl}/user/contact`);
    }

    getUserPackages() {
        return this.getReq(`${apiUrl}/user/packages`);
    }

    getUserExams() {
        return this.getReq(`${apiUrl}/user/exams`);
    }

    updateProfile(user, form) {
        return this.putReq(`${apiUrl}/usersProfile/${user}`, form);
    }

    changePassword(user, form) {
        return this.putReq(`${apiUrl}/changePassword/${user}`, form);
    }

    updateAcademic(user, form) {
        return this.putReq(`${apiUrl}/usersAcademic/${user}`, form);
    }

    updateRegCode(user, form) {
        return this.putReq(`${apiUrl}/usersRegCode/${user}`, form);
    }

    // Maybe User in My Profile
    getInstituteData() {
        return this.getReq(`${apiUrl}/institute`);
    }

    // Maybe User in My Profile
    getStreamData() {
        return this.getReq(`${apiUrl}/stream`);
    }

    getPackagesData() {
        return this.getReq(`${apiUrl}/packages`);
    }

    getPackageBySlug(pkg_slug) {
        const enc_pkg_slug = this.cryptopService.setParam(pkg_slug);
        return this.getReq(`${apiUrl}/packages/${enc_pkg_slug}`);
    }

    getQuestionsData(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/question/${enc_exam_id}`, data);
    }

    getSubject(exam_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/subject/${enc_exam_id}`);
    }

    getTestType() {
        return this.getReq(`${apiUrl}/gettestType`);
    }
    getExam(exam_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/singleExam/${enc_exam_id}`);
    }

    getChapter(exam_id, sub_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_sub_id = this.cryptopService.setParam(sub_id);
        return this.getReq(`${apiUrl}/chapter/${enc_exam_id}/${enc_sub_id}`);
    }

    getChaptersSetData(exam_id, sub_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_sub_id = this.cryptopService.setParam(sub_id);
        return this.getReq(`${apiUrl}/chaptersSetData/${enc_exam_id}/${enc_sub_id}`);
    }

    getchapterDataByExam(exam_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/chapterDataByExam/${enc_exam_id}`);
    }

    // Maybe User in My Profile
    getExamData(stream_id) {
        const enc_stream_id = this.cryptopService.setParam(stream_id);
        return this.getReq(`${apiUrl}/examStudent/${enc_stream_id}`);
    }

    getResultData(data) {
        return this.postReq(`${apiUrl}/result`, data);
    }

    getManualResultData(data) {
        return this.postReq(`${apiUrl}/manual-result`, data);
    }

    studentTestLog(exam_id, form) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.putReq(`${apiUrl}/studentTestLog/${enc_exam_id}`, form);
    }

    studentManualTestLog(exam_id, form) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.putReq(`${apiUrl}/studentManualTestLog/${enc_exam_id}`, form);
    }

    studentTestLogEntry(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/studentTestLogEntry/${enc_exam_id}`, data);
    }

    studentManualTestLogEntry(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/studentManualTestLogEntry/${enc_exam_id}`, data);
    }

    getAvgTime(exam_id, subject_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_subject_id = this.cryptopService.setParam(subject_id);
        return this.getReq(`${apiUrl}/getAvgTime/${enc_exam_id}/${enc_subject_id}`);
    }

    questionCount(exam_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/questionCount/${enc_exam_id}`);
    }

    getAvgTimeByType(test_summaries, type) {
        const enc_test_summaries = this.cryptopService.setParam(test_summaries);
        const enc_type = this.cryptopService.setParam(type);
        return this.getReq(`${apiUrl}/getAvgTimeType/${enc_test_summaries}/${enc_type}`);
    }

    studentTestLogEntryRes(data) {
        return this.postReq(`${apiUrl}/studentTestLogEntryRes`, data);
    }

    examSummaryForTest(test_summary_id) {
        const enc_test_summary_id = this.cryptopService.setParam(test_summary_id);
        return this.getReq(`${apiUrl}/examSummaryForTest/${enc_test_summary_id}`);
    }

    examSummaryForManualTest(test_summary_id) {
        const enc_test_summary_id = this.cryptopService.setParam(test_summary_id);
        return this.getReq(`${apiUrl}/examSummaryForManualTest/${enc_test_summary_id}`);
    }

    getPerformanceTest(test_id) {
        const enc_test_id = this.cryptopService.setParam(test_id);
        return this.getReq(`${apiUrl}/getPerformanceTest/${enc_test_id}`);
    }

    logsForTestResult(test_summary_id) {
        const enc_test_summary_id = this.cryptopService.setParam(test_summary_id);
        return this.getReq(`${apiUrl}/logsForTestResult/${enc_test_summary_id}`);
    }

    logsForManualTestResult(test_summary_id) {
        const enc_test_summary_id = this.cryptopService.setParam(test_summary_id);
        return this.getReq(`${apiUrl}/logsForManualTestResult/${enc_test_summary_id}`);
    }

    ExamSummarySet(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/examSummarySet/${enc_exam_id}`, data);
    }

    examSummarySetPractice(exam_id, sub_id, chapt_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_sub_id = this.cryptopService.setParam(sub_id);
        const enc_chapt_id = this.cryptopService.setParam(chapt_id);
        return this.getReq(`${apiUrl}/examSummaryPracticeSet/${enc_exam_id}/${enc_sub_id}/${enc_chapt_id}`);
    }

    ExamSummarySetFull(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/examSummaryFullSet/${enc_exam_id}`, data);
    }

    ExamSummarySetPrelim(student_id, exam_id, set_id, test_slug_name) {
        const enc_student_id = this.cryptopService.setParam(student_id);
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_set_id = this.cryptopService.setParam(set_id);
        const enc_test_slug_name = this.cryptopService.setParam(test_slug_name);
        return this.getReq(`${apiUrl}/examSummaryPrelim/${enc_student_id}/${enc_exam_id}/${enc_set_id}/${enc_test_slug_name}`);
    }

    ExamSummary(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/examSummary/${enc_exam_id}`, data);
    }

    ManualExamSummary(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/manualExamSummary/${enc_exam_id}`, data);
    }

    addFavQuestion(data) {
        return this.postReq(`${apiUrl}/addFavQuestion`, data);
    }

    removeFavQuestion(data) {
        return this.deleteReq(`${apiUrl}/removeFavQuestion`, data);
    }

    getFavQuestionsData(exam_id, sub_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_sub_id = this.cryptopService.setParam(sub_id);
        return this.getReq(`${apiUrl}/favQuestions/${enc_exam_id}/${enc_sub_id}`);
    }

    gettop20FavQuestion() {
        return this.getReq(`${apiUrl}/top20FavQuestion`);
    }

    getTopQuestionsAnswer() {
        return this.getReq(`${apiUrl}/topQuestionsAnswer`);
    }

    // May be used in Practice test
    askExpert(stud_id, form) {
        const enc_student_id = this.cryptopService.setParam(stud_id);
        return this.putReq(`${apiUrl}/askExpert/${enc_student_id}`, form);
    }

    // May be Used for register
    googleAuth(data) {
        return this.postReq(`${apiUrl}/users/google`, data);
    }

    registerStageUser(data) {
        return this.postReq(`${apiUrl}/users/registerStageUser`, data);
    }

    // May be Used for register
    getProfileDataGoogle(token: string) {
        return this.getReq(`${googleUrl}${token}`);
    }

    fulltestQuestionData(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/fulltestquestion/${enc_exam_id}`, data);
    }

    prelimtestQuestionData(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/prelimtestquestion/${enc_exam_id}`, data);
    }

    manualQuestionData(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/manualtestquestion/${enc_exam_id}`, data);
    }

    previousyearQuestionData(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/previousyearquestion/${enc_exam_id}`, data);
    }

    getFullSets(exam_id, sub_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_sub_id = this.cryptopService.setParam(sub_id);
        return this.getReq(`${apiUrl}/fullSet/${enc_exam_id}/${enc_sub_id}`);
    }

    practiceSets(exam_id, chapt_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_chapt_id = this.cryptopService.setParam(chapt_id);
        return this.getReq(`${apiUrl}/practiceSet/${enc_exam_id}/${enc_chapt_id}`);
    }

    getPrelimSets(exam_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/prelimSet/${enc_exam_id}`);
    }

    getPreviousSets(exam_id, selected_year) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_selected_year = this.cryptopService.setParam(selected_year);
        return this.getReq(`${apiUrl}/previousSet/${enc_exam_id}/${enc_selected_year}`);
    }

    getPreviousYears(exam_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/years/${enc_exam_id}`);
    }

    getPracticeDataSet(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/practiceDataSet/${enc_exam_id}`, data);
    }

    getFullDataSet(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/fullDataSet/${enc_exam_id}`, data);
    }

    getPrelimDataSet(exam_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/prelimDataSet/${enc_exam_id}`);
    }

    getManualSetDetails(set_id) {
        const enc_set_id = this.cryptopService.setParam(set_id);
        return this.getReq(`${apiUrl}/getManualSetDetails/${enc_set_id}`);
    }

    // May be used in My Profile
    getState() {
        return this.getReq(`${apiUrl}/state`);
    }

    userToken(data) {
        return this.postReq(`${apiUrl}/userToken`, data);
    }

    // May be used in My Profile
    getCity() {
        return this.getReq(`${apiUrl}/city`);
    }

    // May be used in Media
    uploadFiles(student_id, formData) {
        const enc_student_id = this.cryptopService.setParam(student_id);
        return this.postReq(`${apiUrl}/uploadFile/${enc_student_id}`, formData);
    }

    // May be used in Media
    getFileData(student_id) {
        const enc_student_id = this.cryptopService.setParam(student_id);
        return this.getReq(`${apiUrl}/getuploadFile/${enc_student_id}`);
    }

    getallExamsData() {
        return this.getReq(`${apiUrl}/allExams`);
    }

    verifyRegCode(data) {
        return this.postReq(`${apiUrl}/verifyRegCode`, data);
    }

    verifyOtp(data) {
        return this.postReq(`${apiUrl}/verifyOtp`, data);
    }

    resendOtp(data) {
        return this.postReq(`${apiUrl}/resendOtp`, data);
    }

    topQuestions(subject_id) {
        const enc_subject_id = this.cryptopService.setParam(subject_id);
        return this.getReq(`${apiUrl}/topQuestions/${enc_subject_id}`);
    }

    examSummaryData(exam_id, test_slug_name) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_test_slug_name = this.cryptopService.setParam(test_slug_name);
        return this.getReq(`${apiUrl}/examSummaryData/${enc_exam_id}/${enc_test_slug_name}`);
    }

    examSummaryDataBySubject(exam_id, sub_id, test_slug_name) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_sub_id = this.cryptopService.setParam(sub_id);
        const enc_test_slug_name = this.cryptopService.setParam(test_slug_name);
        return this.getReq(`${apiUrl}/examSummaryDataSubject/${enc_exam_id}/${enc_sub_id}/${enc_test_slug_name}`);
    }

    examSummaryDataByMock(exam_id, data) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.postReq(`${apiUrl}/examSummaryDataMock/${enc_exam_id}`, data);
    }

    finishTest(data) {
        return this.postReq(`${apiUrl}/finishTest`, data);
    }

    ReportError(data) {
        return this.postReq(`${apiUrl}/reportError`, data);
    }

    // skip payment
    activatePackage(data) {
        return this.postReq(`${apiUrl}/activatePackage`, data);
    }

    // pay
    PayProcess(data) {
        return this.postReq(`${apiUrl}/payment`, data);
    }

    CheckPayment(data) {
        return this.postReq(`${apiUrl}/paymentStatus`, data);
    }

    studentDiscountCode(data) {
        return this.postReq(`${apiUrl}/discount_code`, data);
    }

    getMockSets(exam_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/getMockSets/${enc_exam_id}`);
    }

    getSubjectSets(exam_id, sub_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_sub_id = this.cryptopService.setParam(sub_id);
        return this.getReq(`${apiUrl}/getSubjectSets/${enc_exam_id}/${enc_sub_id}`);
    }

    getChapterStats(exam_id, sub_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_sub_id = this.cryptopService.setParam(sub_id);
        return this.getReq(`${apiUrl}/getChapterStats/${enc_exam_id}/${enc_sub_id}`);
    }

    getChapterSets(exam_id, sub_id, chap_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        const enc_sub_id = this.cryptopService.setParam(sub_id);
        const enc_chap_id = this.cryptopService.setParam(chap_id);
        return this.getReq(`${apiUrl}/getChapterSets/${enc_exam_id}/${enc_sub_id}/${enc_chap_id}`);
    }

    getManualsets(exam_id) {
        const enc_exam_id = this.cryptopService.setParam(exam_id);
        return this.getReq(`${apiUrl}/getManualsets/${enc_exam_id}`);
    }

    pdfTestSummaryEntry(pdf_test_id, data) {
        const enc_pdf_test_id = this.cryptopService.setParam(pdf_test_id);
        return this.postReq(`${apiUrl}/pdfTestSummaryEntry/${enc_pdf_test_id}`, data);
    }

    getPdfTestBySummId(pdf_test_summ_id) {
        const enc_pdf_test_summ_id = this.cryptopService.setParam(pdf_test_summ_id);
        return this.getReq(`${apiUrl}/pdfTestBySummId/${enc_pdf_test_summ_id}`);
    }

    pdfTestLog(data) {
        return this.putReq(`${apiUrl}/pdfTestLog`, data);
    }

    finishTestPdf(data) {
        return this.postReq(`${apiUrl}/finishTestPdf`, data);
    }

    postReq(url, data): Observable<any> {
        return this.http.post(url, data, httpOptions).pipe(
            map(this.extractData),
            catchError(this.handleError),
        );
    }

    getReq(url): Observable<any> {
        return this.http.get(url, httpOptions).pipe(
            map(this.extractData),
            catchError(this.handleError),
        );
    }

    putReq(url, data): Observable<any> {
        return this.http.put(url, data, httpOptions).pipe(
            map(this.extractData),
            catchError(this.handleError),
        );
    }

    deleteReq(url, data): Observable<any> {
        httpOptions['body'] = data;
        return this.http.delete(url, httpOptions).pipe(
            map(this.extractData),
            catchError(this.handleError),
        );
    }
}
