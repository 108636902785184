import { Component, OnInit, Inject } from '@angular/core';
import { NbResetPasswordComponent, NB_AUTH_OPTIONS, getDeepFromObject, NbAuthService } from '@nebular/auth';
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from '../../global';
import { DataService } from '../../admin/data.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api.service';
@Component({
    selector: 'ngx-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent extends NbResetPasswordComponent implements OnInit {
    redirectDelay: number = 0;
    showMessages: any = {};
    strategy: string = '';
    submitted: boolean = false;
    errors: string[] = [];
    messages: string[] = [];
    user: any = {};
    _confirm_show: boolean = false;
    _confirm_pwdType = 'password';
    _new_show: boolean = false;
    _new_pwdType = 'password';
    pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    token: any;

    constructor(
        protected service: NbAuthService,
        @Inject(NB_AUTH_OPTIONS) protected options: {},
        protected cd: ChangeDetectorRef,
        protected router: Router,
        private globals: Globals,
        private dataService: DataService,
        private route: ActivatedRoute,
        private api: ApiService,
    ) {
        super(service, options, cd, router);
        this.redirectDelay = this.getConfigValue('forms.resetPassword.redirectDelay');
        this.showMessages = this.getConfigValue('forms.resetPassword.showMessages');
        this.strategy = this.getConfigValue('forms.resetPassword.strategy');
        this.route.queryParams.subscribe(params => {
            this.token = params['token'];
        });
    }

    ngOnInit() {
        this.globals.sidebarStatus(false);
        this.globals.headerStatus(true);
        this.verifyToken(this.token);
    }

    resetPass(): void {
        const _this = this;
        this.errors = this.messages = [];
        this.submitted = true;
        this.service.resetPassword(this.strategy, this.user).subscribe(function (result) {
            _this.submitted = false;
            if (result.isSuccess()) {
                _this.messages = result.getMessages();
                _this.setMsg(_this.messages , 'success');
            } else {
                _this.errors = result.getErrors();
                _this.setMsg(_this.errors , 'danger');
            }
            const redirect = result.getRedirect();
            if (redirect) {
                setTimeout(function () {
                    return _this.router.navigateByUrl(redirect);
                }, _this.redirectDelay);
            }
            _this.cd.detectChanges();
        });
    }

    setMsg(msg, type) {
        this.dataService.set_msg(msg, type);
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }

    togglepwdType(e) {
        if (e === 'confirm-password') {
            this._confirm_show = !this._confirm_show;
            this._confirm_pwdType = this._confirm_show ? 'text' : 'password';
        }
        if (e === 'new password') {
            this._new_show = !this._new_show;
            this._new_pwdType = this._new_show ? 'text' : 'password';
        }
    }

    verifyToken(token) {
        const value = {
            token: token,
        };
        this.api.verifyToken(value).subscribe(data => {
            if (!data['valid']) {
                this.setMsg('Your password reset link is no longer valid.', 'danger');
                this.router.navigateByUrl('/login');
            }
        });
    }
}
