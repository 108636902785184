import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import * as store from 'store';
const CHECK_INTERVAL = 120000; // in ms
const STORE_KEY = 'lastAction';
import { AuthService } from './auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class AutoLogoutService {
    isTimeout: boolean = false;
    MINUTES_UNTIL_AUTO_LOGOUT = 60; // in Minutes
    autoLogoutInt: any;

    constructor(
        private router: Router,
        private auth: AuthService,
        private ngZone: NgZone,
        private modalService: NgbModal,
    ) {}

    get lastAction() {
        if (this.auth.isLoggedIn) {
            return parseInt(store.get(STORE_KEY), 10);
        }
    }

    set lastAction(value) {
        if (this.auth.isLoggedIn) {
            store.set(STORE_KEY, value);
        }
    }

    initListener() {
        this.ngZone.runOutsideAngular(() => {
            document.body.addEventListener('click', () => this.reset());
        });
    }

    initInterval() {
        this.ngZone.runOutsideAngular(() => {
            this.autoLogoutInt = setInterval(() => {
                this.check();
            }, CHECK_INTERVAL);
        });
    }

    removeInterval() {
        this.ngZone.runOutsideAngular(() => {
            clearInterval(this.autoLogoutInt);
        });
    }

    closeModalService() {
        if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
        }
    }

    removeListener() {
        this.ngZone.runOutsideAngular(() => {
            document.body.removeEventListener('click', () => this.reset());
        });
    }

    reset() {
        this.lastAction = Date.now();
    }

    check() {
        if (this.auth.isLoggedIn) {
            const now = Date.now();
            const timeleft = this.lastAction + this.MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000;
            const diff = timeleft - now;
            this.isTimeout = diff < 0;

            this.ngZone.run(() => {
                if (this.isTimeout) {
                    store.set('isTimeout', this.isTimeout);
                    this.removeInterval();
                    this.removeListener();
                    this.closeModalService();
                    this.router.navigate(['logout']);
                }
            });
        }
    }
}
